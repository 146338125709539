export const initialState = {
    osaSupplementData: {
        existingLast: false,
        updateLast: false,
        PLT: false,
        ELI: false,
        material: '',
        note: '',
        left: {
            supplementCreator: false,
            level: false,
            heelHeight: '',
            version: '',
            heelThickness: '',
            toeThickness: '',
            waist: '',
            ballThicknessMedial: '',
            ballThicknessLateral: '',
            material: '',
            rocker: '',
            schoring: '',
            secondSupplement: '',
            fittingShoe: ''
        },
        right: {
            supplementCreator: false,
            level: false,
            heelHeight: '',
            version: '',
            heelThickness: '',
            toeThickness: '',
            waist: '',
            ballThicknessMedial: '',
            ballThicknessLateral: '',
            rocker: '',
            schoring: '',
            secondSupplement: '',
            fittingShoe: ''
        }
    },
    osaSupplementAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    osaSupplementErrors: {
        message: [],
        existingLast: false,
        updateLast: false,
        PLT: false,
        ELI: false,
        material: false,
        left: {
            supplementCreator: false,
            level: false,
            heelHeight: false,
            version: false,
            heelThickness: false,
            toeThickness: false,
            waist: false,
            ballThicknessMedial: false,
            ballThicknessLateral: false,
            material: false,
            contrefort: false,
            rocker: false,
            schoring: false,
            secondSupplement: false,
            fittingShoe: false
        },
        right: {
            supplementCreator: false,
            level: false,
            heelHeight: false,
            version: false,
            heelThickness: false,
            toeThickness: false,
            waist: false,
            ballThicknessMedial: false,
            ballThicknessLateral: false,
            contrefort: false,
            rocker: false,
            schoring: false,
            secondSupplement: false,
            fittingShoe: false
        }
    }
}
