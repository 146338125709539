import React from 'react'
import { Grid, Typography } from '@mui/material'
import useStyles from './style'
import { withStyles } from '@mui/styles'

const AppTitle = ({ classes, title }) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.titleContainer}
        >
            <Typography className={classes.ApplicationTitle} variant="h2" component="h2">{title}</Typography>
        </Grid>
    )
}

export default withStyles(useStyles)(AppTitle)
