// import UserService from './userService'

class DataProvider {
    /**
     * Constructor function
     */
    constructor () {
        this.folder = 'default'
    }

    /**
     * Get insole default values
     *
     * @returns a pallete
     */
    get insole () {
        let insole = null
        switch (this.folder) {
        default:
            insole = require('./data/default/insole')
            break
        }
        return insole
    }

    /**
     * Get osbOutsole default values
     *
     * @returns a pallete
     */
    get osbOutsole () {
        let osbOutsole = null
        switch (this.folder) {
        default:
            osbOutsole = require('./data/default/osbOutsole')
            break
        }
        return osbOutsole
    }

    /**
     * Get osb default values
     *
     * @returns a pallete
     */
    get osb () {
        let osb = null
        switch (this.folder) {
        default:
            osb = require('./data/default/osb')
            break
        }
        return osb
    }

    /**
     * Get osaPattern default values
     *
     * @returns a pallete
     */
    get osaPattern () {
        let osaPattern = null
        switch (this.folder) {
        default:
            osaPattern = require('./data/default/osaPattern')
            break
        }
        return osaPattern
    }

    /**
     * Get osaPattern default values
     *
     * @returns a pallete
     */
    get osaFinish () {
        let osaFinish = null
        switch (this.folder) {
        default:
            osaFinish = require('./data/default/osaFinish')
            break
        }
        return osaFinish
    }

    /**
     * Get osaSupplement default values
     *
     * @returns a pallete
     */
    get osaSupplement () {
        let osaSupplement = null
        switch (this.folder) {
        default:
            osaSupplement = require('./data/default/osaSupplement')
            break
        }
        return osaSupplement
    }

    /**
     * Get osaLower default values
     *
     * @returns a pallete
     */
    get osaLower () {
        let osaLower = null
        switch (this.folder) {
        default:
            osaLower = require('./data/default/osaLower')
            break
        }
        return osaLower
    }

    /**
     * Get sampleShoe default values
     *
     * @returns a pallete
     */
    get sampleShoe () {
        let sampleShoe = null
        switch (this.folder) {
        default:
            sampleShoe = require('./data/default/sampleShoe')
            break
        }
        return sampleShoe
    }

    /**
     * Get osaPattern default values
     *
     * @returns a pallete
     */
    get repair () {
        let repair = null
        switch (this.folder) {
        default:
            repair = require('./data/default/repair')
            break
        }
        return repair
    }

    /**
     *
     * @param {*} value
     */
    setFolder (value) {
        this.folder = value
    }

    /**
     *
     * @returns
     */
    getFolder () {
        return this.folder
    }
}

// eslint-disable-next-line new-cap
export default new DataProvider()
