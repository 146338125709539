import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields } from './validation.js'
import { validateMinMax } from '../genericFunctions/index.js'
import dataProvider from '../../../lib/dataProvider.js'

const data = dataProvider.osaPattern

const osaPatternSlice = createSlice({
    name: 'osaPattern',
    initialState,
    reducers: {
        osaPatternData (state, action) {
            const key = action.payload.key
            const value = action.payload.value === null ? '' : action.payload.value
            const side = action.payload.side || ''
            const subKey = action.payload.subKey || ''
            switch (key) {
            case 'mainLeather' :
            case 'firstExtraLeather':
            case 'secondExtraLeather':
            case 'thirdExtraLeather':
                state.osaPatternData[key][subKey] = value
                break
            default:
                if (side === '') {
                    state.osaPatternData[key] = value
                } else {
                    if (side === 'left' && state.osaPatternData.left[key] === state.osaPatternData.right[key]) {
                        state.osaPatternData.left[key] = value
                        state.osaPatternData.right[key] = value
                    } else {
                        state.osaPatternData[side][key] = value
                    }
                }
                // check if theres a minMax value
                if (data[key] !== undefined && data[key].min !== undefined) {
                    validateMinMax(data[key], state, action, 'osaPatternErrors')
                }
                break
            }
        },
        osaPatternAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                if (!state.osaPatternAccordion.hasBeenOpened && state.osaPatternAccordion.accordion && !action.payload.value) {
                    state.osaPatternAccordion.hasBeenOpened = true
                }
                state.osaPatternAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.osaPatternAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.osaPatternAccordion.hasBeenOpened = true
                break
            }
        },
        osaPatternClose (state) {
            state.osaPatternAccordion.accordion = false
            state.osaPatternAccordion.hasBeenOpened = true
            validateAllFields(state)
        },
        importOsaPattern (state, action) {
            state.osaPatternData = action.payload
        },
        resetState (state) {
            state.osaPatternData = initialState.osaPatternData
        }
    }
})

export const { resetState, osaPatternData, osaPatternAccordion, importOsaPattern, osaPatternClose } = osaPatternSlice.actions
export default osaPatternSlice.reducer
