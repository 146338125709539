import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './style'
import TherapistDisplay from '../../components/TherapistDisplay'
import NavigationButtons from '../../components/NavigationButtons'
import { withStyles } from '@mui/styles'
import General from '../../tabs/general'
import OsaSupplement from '../../tabs/osaSupplement'
import { setOrderType } from '../../store/reducers/general'
import { useNavigate } from 'react-router-dom'
import ValidationErrorDialog from '../../components/ValidationErrorDialog'

const FittingShoePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const other = useSelector(state => state.other)

    useEffect(() => {
        if (other.allValid) {
            navigate('/overview')
        }
    }, [other.allValid, other.errorDialog])

    /**
     */
    useEffect(() => {
        dispatch(setOrderType('Passchoen'))
    }, [])
    return (
        <div>
            <TherapistDisplay />
            <NavigationButtons />
            <General />
            <OsaSupplement/>
            <ValidationErrorDialog />
            <NavigationButtons />
        </div>
    )
}
export default withStyles(useStyles)(FittingShoePage)
