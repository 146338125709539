import { alpha } from '@mui/material'
import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    root: {
        height: '100%'
    },
    loading: {
        margin: 0
    },
    title: {
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    therapistInput: {
        color: 'black'
    },
    inputField: {
        marginTop: 25
    },
    autoComplete: {
        marginTop: 16
    },
    accordionError: {
        backgroundColor: theme.palette.error.light
    },
    accordionSuccess: {
        backgroundColor: theme.palette.success.light
    },
    titleError: {
        fontWeight: 'bold',
        color: theme.palette.error.main
    },
    titleSuccess: {
        fontWeight: 'bold',
        color: theme.palette.success.main
    },
    formLabel: {
        marginBottom: 16
    },
    button: {
        marginTop: 25
    },
    checkBox: {
        marginTop: 45,
        marginLeft: 0
    },
    postponeOrderCheckbox: {
        marginTop: 25,
        marginLeft: 0
    },
    voetmaxCheckbox: {
        marginTop: 22,
        marginLeft: 0
    },
    search: {
        marginTop: 16,
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.black, 0.1),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.15)
        },
        marginRight: theme.spacing(2),
        marginLeft: '',
        width: '100%'
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.15)
        },
        height: '100%',
        position: 'absolute',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputInput: {
        color: 'inherit',
        width: '100%',
        '& .MuiInputBase-input': {
            padding: theme.spacing(2, 2, 2, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch'
            }
        }
    }
}))

export default styles
