export const developMethod = [
    'Gelijmd',
    'Doorgenaaid',
    'Flexibel'
]

export const soleSpace = [
    '-',
    '3mm L+R',
    '5mm L+R'
]

export const insideSole = [
    'Kunststof',
    'Leer'
]

export const cambering = [
    '-',
    '1+4 staal dames + thermodek',
    '2+4 staal heren + thermodek',
    '2+5 staal heren + polyprop'
]

export const contrefort = [
    '-',
    'Normaal',
    'Hoog binnen',
    'Hoog buiten',
    'Hoog buiten en binnen',
    'Rondom',
    'Rondom U',
    'Peroneus',
    'TVO I',
    'TVO 2',
    'TVO 3a',
    'TVO 3b',
    'TVO 4',
    'TVO 5',
    'TVO 6',
    'TVO 7',
    'TVO 8',
    'TVO 9',
    'TVO 10',
    'TVO 11',
    'TVO 12',
    'TVO 13'
]

export const contrefortMaterial = [
    '-',
    '1. Leer',
    '2. Rhenoflex (groen)',
    '3. Dompel (wit)',
    '4. Erkoflex',
    '5. Ping pong'
]

export const schoringCTLateral = [
    '0,5 lat',
    '1,0 lat',
    '1,5 lat',
    '2,0 lat',
    '2,5 lat',
    '3,0 lat'
]

export const schoringCTMedial = [
    '0,5 med',
    '1,0 med',
    '1,5 med',
    '2,0 med',
    '2,5 med',
    '3,0 med'
]

export const nose = [
    '-',
    'Normaal',
    'Uitgehold',
    'Veiligheid',
    'Kruip'
]

export const noseMaterial = [
    'Leer',
    'Kunststof',
    'Diabetisch',
    'Erkoflex',
    'Ping pong',
    'Koolstof veiligheid',
    'Staal veiligheid',
    'Aluminium',
    'Renoflex'
]

export const sideLining = [
    '-',
    'Leer',
    'Kunstof mat'
]

export const edges = [
    '-',
    'Bal-Bal',
    'Hak-Hak',
    'Rondom'
]

export const rocker = [
    'Versnelde afwikkeling',
    'Vertraagde afwikkeling',
    '2-fasen',
    'Polyfasische afwikkeling'
]

export const heelHeight = {
    min: 0,
    max: 50
}

export const rounding = { min: 0 }

export const mudguard = [
    '-',
    'Bal-bal',
    'Hak-hak',
    'Rondom over hak',
    'Rondom niet over hak'
]

export const mudguardMaterial = [
    'Leer',
    'Rubber 35 (zwart)',
    'Rubber 36 (bruin)',
    'Poro',
    'Paralite',
    'vd Velden band'
]

export const midSole = [
    '1. Leer 2-2.5mm',
    '2. Leer-rubber 4 mm',
    '3. Rubber 2 mm',
    '8. Poro Afwikkelend 0,5 cm',
    '9. poro Afwikkelend 1 cm'
]

export const outSole = [
    '-',
    'Leer',
    'Leer+ antislip',
    'Poro',
    'Rubber',
    'Profielzool',
    'Zolen uit plaat',
    'Vormzolen',
    'Antislip zolen',
    'Vormzolen + achterlap leer',
    'Kuipzolen'
]

export const toeParts = [
    '-',
    'Leer',
    'Nylon'
]

export const boardSize = [
    '40',
    '42',
    '44',
    '46',
    '48',
    '50',
    '52',
    '54'
]

export const heelMaterial = [
    '-',
    '1 - Leer',
    '2 - Hout overleer',
    '3 - Hout reepjesleer',
    '4 - Eva opbouw',
    '5 - Eva opbouw lichtgewicht',
    '6 - Bufferhak',
    '12 - Bufferspie',
    '4+12 - Eva + bufferspie',
    '5+12 - Eva licht + bufferspie'
]

export const heelModel = [
    'Blokhak',
    'Niet uitgeholde sleehak',
    'Helemaal uitgeholde sleehak',
    'Mediaal uitgeholde sleehak',
    'Lateraal uitgeholde sleehak'
]

export const woodenHeelType = [
    'Blokhak',
    'E-hak'
]

export const heelStructure = [
    'Hak op zool',
    'Aangesloten'
]

export const schoringLat = [
    '0,5 lat',
    '1,0 lat',
    '1,5 lat',
    '2,0 lat',
    '2,5 lat',
    '3,0 lat'
]

export const schoringMed = [
    '0,5 med',
    '1,0 med',
    '1,5 med',
    '2,0 med',
    '2,5 med',
    '3,0 med'
]

export const edgesMaterial = [
    '1 - Zwart rolband',
    '2 - Naturel rolband',
    '3 - Bruin rolband',
    '4 - Zwart platte rand',
    '5 - Naturel rolrand',
    '5a - Bruin rolband',
    '6 - Zwart Schulprand',
    '7 - Naturel Schulprand',
    '8 - Bruin schulprand',
    '9 - Zwart rand met prik fijn',
    '10 - Donkerbruin rand met',
    '11 - Naturel rand met prik fijn',
    '15 - Zwart rand met prik grof',
    '16 - Donkerbruin rand met prik grof',
    '17 - Naturel rand met prik grof',
    '18 - Naturel openstaande rand',
    '19 - Zwart openstaande golf',
    '20 - Donkerbruin openstaande golf met prik en steek',
    '21 - Zwart openstaande rol',
    '22 - Donkerbruin openstaande rol hoog met steek',
    '23 - Zwart platte rand met schulp',
    '24 - Donkerbruine platte rand',
    '25 - Naturel platte rand met',
    '27 - Zwarte platte kartel  grof',
    '28 - Donkerbruine platte kartel',
    '29 - Naturel platte rand/afge',
    '30 - Naturel platte rand 12n',
    '31 - Naturel rol en platte lip',
    '40 - Zwart Eva glad+lip',
    '40a - Zwart Eva glad z. lip',
    '41 - Donkerbruin EVa glad +',
    '42 - Leger groen Eva glad +',
    '42a - Leger groen Eva glad',
    '43 - Donkergrijs Eva glad +',
    '43a - Donkergrijs Eva glad z.',
    '44 - Lichtbruin Eva glad + lip',
    '44a - Lichtbruin Eva glad z.',
    '45a - Middengrijs Eva glad',
    '45 - Middengrijs Eva glad +',
    '46a - Honing Eva glad z. lip',
    '46 - Honing Eva glad +lip',
    '47 - Wit Eva glad + lip',
    '47a - Wit Eva glad z. lip',
    '48a - Zand Eva glad z. lip',
    '48 - Zand Eva glad + lip',
    '49a - Ijswit Eva glad z. lip',
    '49 - Ijswit Eva glad + lip',
    '50a - Beige Eva glad z. lip',
    '50 - Beige Eva glad + lip',
    '51a - Donkerblauw Eva glad z. lip',
    '51 - Donkerblauw Eva glad + lip',
    '52 - Rood Eva glad + lip',
    '52a - Rood Eva glad z. lip',
    '53 - Groen eva glad + lip',
    '53a - Groen Eva glad z. lip',
    '54a - Kobalt blauw Eva glad z. lip',
    '54 - Kobalt blauw Eva glad + lip',
    '55 - Zwart ribbel fijn rubber',
    '56 - Donker bruin ribbel fijn',
    '57 - Zwart ribbel grof rubber',
    '58 - Donker bruin ribbel grof',
    '59 - Zwart rol fijn met steek',
    '60 - Donkerbruin fijne rol met',
    '61 - Grijs fijne rol met steek',
    '62 - Zwart openstaande kan',
    '63 - Donkerbruin openstaan',
    '64 - Zwart openstaande kartel',
    '65 - Donkerbruin openstaan',
    '66 - Zwart openstaand rol hoog',
    '67 - Donkerbruin openstaand',
    '68 - Zwart openstaande sch',
    '69 - Donkerbruin openstaande',
    '70 - Zwart openstaande kuio',
    '71 - Donkerbruin openstaand',
    '80 - Grijs met oranje rol',
    '81 - Zand, wit en grijs'
]

export const anklePadding = [
    'Geheel',
    'Alleen enkel',
    'Enkels extra',
    'Polsterkraag'
]

export const anklePaddingMaterial = [
    'PPT',
    'Lunairmed',
    'XRD'
]

export const schoring = [
    '0,5 lat',
    '1,0 lat',
    '1,5 lat',
    '2,0 lat',
    '2,5 lat',
    '3,0 lat',
    '0,5 med',
    '1,0 med',
    '1,5 med',
    '2,0 med',
    '2,5 med',
    '3,0 med'
]

export const backflap = [
    'Gelijk aan zool',
    'Vormhak',
    'Neotrans (hard)',
    'Neotrans (zacht)'
]

export const heelBuffer = [
    'Bufferspie',
    'Bufferhak'
]

export const soleNumber = [
    '20 - Donker blauw gondola 6mm',
    '20a - Donker blauw gondola 8mm',
    '21 - Beige gondola 6mm',
    '21a - Beige gondola 8mm',
    '22 - Taupe gondola 6mm',
    '22a - Taupe gondola 8mm',
    '23 - IJswit gondola 6mm',
    '23a - IJswit gondola 8mm',
    '24 - Wit gondola 6mm',
    '24a - Wit gondola 8mm',
    '25 - Lichtgrijs gondola 6mm',
    '25a - Lichtgrijs gondola 8mm',
    '27 - Zwart gondola 6mm',
    '27a - Zwart gondola 8mm',
    '28 - Bruin gondola 6mm',
    '28a - Bruin gondola 8mm',
    '29 - Beige poroline 6mm',
    '29a - Beige poroline 8mm',
    '31 - Zwart poroline 6mm',
    '31a - Zwart poroline 8mm',
    '33 - Bruin poroline 6mm',
    '33a - Bruin poroline 8mm',
    '35 - Zwart rubber 4mm',
    '35a - Zwart rubber 6mm',
    '36 - Bruin rubber 4mm',
    '36a - Bruin rubber 6mm',
    '37 - Taupe rubber 4mm',
    '37a - Taupe rubber 6mm',
    '38 - Beige rubber 4mm',
    '38a - Beige rubber 6mm',
    '39 - Honing rubber 4mm',
    '39a - Honing rubber 6mm',
    '40 - IJswit rubber 4mm',
    '40a - IJswit rubber 6mm',
    '42 - Wit rubber 4mm',
    '42a - Wit rubber 6mm',
    '43 - Donkergrijs rubber 4mm',
    '43a - Donkergrijs rubber 6mm',
    '45 - Legergroen rubber 4mm',
    '45a - Legergroen rubber 6mm',
    '46 - Donker blauw rubber 4mm',
    '46a - Donker blauw rubber 6mm',
    '48 - Donker rood rubber 4mm',
    '48a - Donker rood rubber 6mm',
    '50 - Zwart paralite 5mm',
    '52 - Zwart visgraat 5 mm',
    '53 - Wit visgraat 5 mm',
    '54 - Zwart autoband 5,5 mm',
    '56 - Honing autoband 5,5 mm',
    '57 - Bruin autoband 5,5 mm',
    '58 - Wit Birkenstock EVA 7,5 mm',
    '59 - Bruin Birkenstock EVA 7,5 mm',
    '60 - Zwart Birkenstock EVA 7,5 mm',
    '67 - Zwart Vibram fijn 6mm',
    '67a - Zwart Vibram fijn 8mm',
    '68 - Bruin Vibram fijn 6mm',
    '68a - Bruin Vibram fijn 8mm',
    '69 - Zwart Track onderwerk',
    '70 - Moreno Track onderwerk',
    '71 - Zwart kiel 6mm',
    '72 - Zwart lubeck PUR 9mm',
    '73 - Olie-zuur bestendig track onderwerk',
    '74 - Zwart onderwerk',
    '75 - Moreno onderwerk',
    '76 - Honing onderwerk',
    '77 - Zwart vibram 1220 8mm',
    '78 - Donkerbruin vibram 1220 8mm',
    '79 - Honing vibram 1220 8mm',
    '80 - Zwart Vibram onderwerk',
    '81 - Moreno Vibram onderwerk',
    '82 - Honing Vibram onderwek',
    '83 - Zwart vibram 2644 gumlite',
    '84 - Donkerbruin vibram 2644 gumlite',
    '85 - Zwart Vibram',
    '86 - Moreno Vibram',
    '87 - Leder voor antislip zolen 3mm',
    '88 - Leder voor antislip zolen 5mm',
    '89 - Leder zolen 3mm',
    '90 - Leder zolen 5mm',
    '91 - Leer JR conti classic 6mm',
    '94 - Zwart Vibram 2002',
    '98 - Moreno',
    '102 - Zwart supernewflex 4mm',
    '102a - Zwart supernewflex 6mm',
    '103 - Donkerbruin supernewflex 4mm',
    '103a - Donkerbruin supernewflex 6mm',
    '104a - IJswit supernewflex 6mm',
    '105a - Honing supernewflex 6mm',
    '110 - Nylon anti-slip 1,8mm zwart',
    '111 - Nylon anti-slip 1,8mm donk bruin',
    '112 - Nylon anti-slip 1,8mm bruin',
    '113 - Zwart prefab antislip zool 2mm',
    '114 - Donkerbruin prefab antislip zool 2mm',
    '115 - Honing prefab antislip zool 2mm',
    '116 - Luxor 6mm Honing',
    '117 - Zwart vibram 1030 6mm',
    '118 - Donkerbruin vibram 1030 6mm',
    '119 - Zwart vibram 2667 8mm',
    '120 - Donkerbruin vibram 2667 8mm',
    '121 - Zwart spider 5,5mm',
    '122 - Honing spider 5,5mm',
    '135 - Annazool Zwart',
    '136 - Annazool Moreno',
    '137 - Annazool Taupe',
    '138 - Annazool Beige',
    '139 - Annazool Honing',
    '140 - Annazool ijswit',
    '142 - Annazool Wit',
    '143 - Annazool Grijs',
    '145 - Annazool Camel',
    '146 - Annazool Donkerblauw',
    '148 - Zwart/Oranje emma vormzool 8mm',
    '150 - Zwart dunlop 4,5mm',
    '151 - Wit dunlop 4,5mm',
    '152 - Geel dunlop 4,5mm',
    '153 - Oranje dunlop 4,5mm',
    '154 - Rood dunlop 4,5mm',
    '155 - Groen dunlop 4,5mm',
    '156 - Blauw dunlop 4,5mm',
    '160 - Zwart astro star 4mm',
    '160a - Zwart astro star 6mm',
    '161 - Donkerbruin astro star 4mm',
    '161a - Donkerbruin astro star 6mm',
    '162 - Taupe astro star 4mm',
    '162a - Taupe astro star 6mm',
    '163 - Beige astro star 4mm',
    '163a - Beige astro star 6mm',
    '164 - IJswit astro star 4mm',
    '164a - IJswit astro star 6mm',
    '165 - Wit astro star 4mm',
    '165a - Wit astro star 6mm',
    '166 - Donkergrijs astro star 4mm',
    '166a - Donkergrijs astro star 6mm',
    '181 - Sebago Zwart 4mm',
    '181a - Sebago Zwart 6mm',
    '182 - Sebago Donkerbruin 4mm',
    '182a - Sebago Donkerbruin 6mm',
    '190 - Zwart sportzool 6mm',
    '191 - Donkerbruin sportzool 6mm',
    '192 - Wit sportzool 6mm',
    '193 - Grijs sportzool 6mm',
    '201 - Zwart Vibram 1485 USHBA Kuip',
    '202 - Zwart Vibram 056C winter city kuip',
    '205 - Zwart jura ortho kuip',
    '206 - Zwart jura kuip',
    '207 - Zwart athen kuip'
]
