import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import useStyles from './style'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { patientData, setError, setOrderType } from '../../store/reducers/general'
import { useDispatch } from 'react-redux'

import { withStyles } from '@mui/styles'
import api from '../../lib/api'
import moment from 'moment'
import { sampleShoeData } from '../../store/reducers/sampleShoe'
import { setPreviousOrders } from '../../store/reducers/other'

const OSAWit = require('../../assets/Icon - OSA schoen wit.svg')

const OSAMainPage = ({ classes }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [queryParameters] = useSearchParams()
    const [patientId, setPatientID] = useState('')
    /**
     * get the authenticated podiatrist's name and email
     * (activated only in production)
     */
    useEffect(() => {
        // save the patient number when given.
        const patient = queryParameters.get('patient')
        if (patient !== null) dispatch(patientData({ key: 'number', value: patient }))

        const fetchTherapists = () => {
            api.therapists((rsp) => {
                dispatch(patientData({
                    key: 'therapist',
                    value: {
                        name: rsp.name,
                        email: rsp.email
                    }
                }),
                api.setSharepointInformation(rsp.microsoftToken, queryParameters)
                )
            }, (error) => { dispatch(setError(error)) })
        }

        fetchTherapists()
        if (patient === null) return
        api.patients(patient,
            (data) => {
                const bday = moment(data.birthDate).add(2, 'h')
                const lasts = [{ formattedName: '-' }]
                data.lasts.forEach(element => {
                    if (element.removed === null) {
                        lasts.push(element)
                    }
                })
                dispatch(patientData({ key: 'number', value: patient }))
                dispatch(patientData({ key: 'lastName', value: data.lastName }))
                dispatch(patientData({ key: 'birthdate', value: bday }))
                dispatch(patientData({ key: 'gender', value: data.sex.toLowerCase() }))
                dispatch(patientData({ key: 'id', value: `${data.id}` }))
                dispatch(patientData({ key: 'initials', value: `${data.initials}` }))
                dispatch(patientData({ key: 'activeLasts', value: lasts }))
                if (lasts.length === 2) {
                    dispatch(patientData({ key: 'selectedLast', value: lasts[1].formattedName }))
                }
                setPatientID(data.id)
            }, (error) => {
                dispatch(setError(error))
            })
    }, [])

    /**
     * Continue to order page.
     */
    function toOSA () {
        navigate('/osaShoe')
        dispatch(setOrderType('OSA'))

        if (patientId === '') return
        api.getOrder(patientId, 'osa', (data) => {
            const parsedData = []
            data.forEach(element => {
                parsedData.push(JSON.parse(element))
            })
            const orders = parsedData.sort((a, b) => b.senddate - a.senddate)
            dispatch(setPreviousOrders(orders))
        }, (error) => {
            dispatch(setError(error))
        })
    }

    /**
     * Continue to order page.
     */
    function toSampleShoe () {
        navigate('/sampleShoe')
        dispatch(setOrderType('Proefschoen'))

        if (patientId === '') return
        api.getOrder(patientId, 'sampleshoe', (data) => {
            const parsedData = []
            data.forEach(element => {
                parsedData.push(JSON.parse(element))
            })
            const orders = parsedData.sort((a, b) => b.senddate - a.senddate)
            dispatch(setPreviousOrders(orders))
        }, (error) => {
            dispatch(setError(error))
        })
    }

    /**
     * Continue to order page.
     */
    function toFittingShoe () {
        navigate('/fittingShoe')
        dispatch(setOrderType('Passchoen'))

        if (patientId === '') return
        api.getOrder(patientId, 'passchoen', (data) => {
            const parsedData = []
            data.forEach(element => {
                parsedData.push(JSON.parse(element))
            })
            const orders = parsedData.sort((a, b) => b.senddate - a.senddate)
            dispatch(setPreviousOrders(orders))
        }, (error) => {
            dispatch(setError(error))
        })
    }

    /**
     * Continue to order page.
     */
    function toVlos () {
        navigate('/VLOS')
        dispatch(setOrderType('VLOS'))
        dispatch(sampleShoeData({ key: 'footwearType', side: 'left', value: 'VLOS' }))
        dispatch(sampleShoeData({ key: 'footwearType', side: 'right', value: 'VLOS' }))

        if (patientId === '') return
        api.getOrder(patientId, 'vlos', (data) => {
            const parsedData = []
            data.forEach(element => {
                parsedData.push(JSON.parse(element))
            })
            const orders = parsedData.sort((a, b) => b.senddate - a.senddate)
            dispatch(setPreviousOrders(orders))
        }, (error) => {
            dispatch(setError(error))
        })
    }

    return (
        <Grid justifyContent="center" alignItems="center" container spacing={5}>
            <Grid item xs={3} onClick={toFittingShoe}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.tertiary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={OSAWit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>Pas/-folieschoen</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} onClick={toSampleShoe}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.secondary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={OSAWit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>Proef/-voeringschoen</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} onClick={toOSA}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.secondary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={OSAWit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>OSA schoen</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} onClick={toVlos}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.primary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={OSAWit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>VLOS</Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default withStyles(useStyles)(OSAMainPage)
