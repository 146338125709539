import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields } from './validation.js'
import { validateMinMax } from '../genericFunctions/index.js'
import dataProvider from '../../../lib/dataProvider.js'

const data = dataProvider.osaLower

const osaLowerSlice = createSlice({
    name: 'osaLower',
    initialState,
    reducers: {
        osaLowerData (state, action) {
            const key = action.payload.key
            const value = action.payload.value === null ? '' : action.payload.value
            const side = action.payload.side || ''
            switch (key) {
            default:
                if (side === '') {
                    state.osaLowerData[key] = value
                } else {
                    if (side === 'left' && state.osaLowerData.left[key] === state.osaLowerData.right[key]) {
                        state.osaLowerData.left[key] = value
                        state.osaLowerData.right[key] = value
                    } else {
                        state.osaLowerData[side][key] = value
                    }
                }
                // check if theres a minMax value
                if (data[key] !== undefined && data[key].min !== undefined) {
                    validateMinMax(data[key], state, action, 'osaLowerErrors')
                }
                break
            }
        },
        osaLowerAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                if (!state.osaLowerAccordion.hasBeenOpened && state.osaLowerAccordion.accordion && !action.payload.value) {
                    state.osaLowerAccordion.hasBeenOpened = true
                }
                state.osaLowerAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.osaLowerAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.osaLowerAccordion.hasBeenOpened = true
                break
            }
        },
        osaLowerClose (state) {
            state.osaLowerAccordion.accordion = false
            state.osaLowerAccordion.hasBeenOpened = true
            validateAllFields(state)
        },
        importOsaLower (state, action) {
            state.osaLowerData = action.payload
        },
        resetState (state) {
            state.osaLowerData = initialState.osaLowerData
        }
    }
})

export const { resetState, osaLowerData, osaLowerAccordion, importOsaLower, osaLowerClose } = osaLowerSlice.actions
export default osaLowerSlice.reducer
