import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    Box,
    styled
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { insoleAccordion, insoleData } from '../../store/reducers/insole'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'
import { deepCopy } from '../../lib/util'

const data = dataProvider.insole

const OSB = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const insole = useSelector((state) => state.insole.insoleData)
    const error = useSelector((state) => state.insole.insoleErrors)
    const tab = useSelector((state) => state.insole.insoleAccordion)
    const [expanded, setExpanded] = React.useState(['panel1'])

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(insoleAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Steunzool</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Grondzool/ blok</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                    error={error.developMethod}
                                >
                                    <FormLabel>Maakwijze*</FormLabel>
                                    <Autocomplete
                                        name="developmethod"
                                        id="developmethod" label="developmethod"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'developMethod', value }))}
                                        options={data.developMethod}
                                        value={insole.developMethod}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.developMethod}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                    error={error.insoleType}
                                >
                                    <FormLabel>Soort*</FormLabel>
                                    <Autocomplete
                                        name="insoleType"
                                        id="insoleType" label="insoleType"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'insoleType', value }))}
                                        options={data.insoleType}
                                        value={insole.insoleType}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.insoleType}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                    error={error.stiffness}
                                >
                                    <FormLabel>Hardheid*</FormLabel>
                                    <Autocomplete
                                        name="stiffness"
                                        id="stiffness" label="stiffness"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'stiffness', value }))}
                                        options={data.stiffness}
                                        value={insole.stiffness}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.stiffness}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.soleSpecification}
                                >
                                    <FormLabel>Uitvoering*</FormLabel>
                                    <Autocomplete
                                        name="soleSpecification"
                                        id="soleSpecification" label="soleSpecification"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'soleSpecification', value }))}
                                        options={data.soleSpecification}
                                        value={insole.soleSpecification}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.soleSpecification}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.soleMaterial}
                                >
                                    <FormLabel>Materiaal*</FormLabel>
                                    <Autocomplete
                                        name="soleMaterial"
                                        id="soleMaterial" label="soleMaterial"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'soleMaterial', value }))}
                                        options={data.soleMaterial}
                                        value={insole.soleMaterial}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.soleMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Elementen achter</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>

                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.correction}
                                >
                                    <FormLabel>Correctie links*</FormLabel>
                                    <Autocomplete
                                        name="correction"
                                        id="correction" label="correction"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'correction', side: 'left', value }))}
                                        options={data.correction}
                                        value={insole.left.correction}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.correction}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.correctionHeight}
                                >
                                    <FormLabel>Hoogte correctie links</FormLabel>
                                    <Autocomplete
                                        name="correctionHeight"
                                        id="correctionHeight" label="correctionHeight"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'correctionHeight', side: 'left', value }))}
                                        options={data.correctionHeight}
                                        value={insole.left.correctionHeight}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.correctionHeight}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.correction}
                                >
                                    <FormLabel>Correctie rechts*</FormLabel>
                                    <Autocomplete
                                        name="correction"
                                        id="correction" label="correction"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'correction', side: 'right', value }))}
                                        options={data.correction}
                                        value={insole.right.correction}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.correction}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.correctionHeight}
                                >
                                    <FormLabel>Hoogte correctie rechts</FormLabel>
                                    <Autocomplete
                                        name="correctionHeight"
                                        id="correctionHeight" label="correctionHeight"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'correctionHeight', side: 'right', value }))}
                                        options={data.correctionHeight}
                                        value={insole.right.correctionHeight}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.correctionHeight}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.heelLift}
                                >
                                    <FormLabel>Hielheffing links*</FormLabel>
                                    <Autocomplete
                                        name="heelLift"
                                        id="heelLift" label="heelLift"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'heelLift', side: 'left', value }))}
                                        options={data.heelLift}
                                        value={insole.left.heelLift}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.heelLift}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heelLift}
                                >
                                    <FormLabel>Hielheffing rechts*</FormLabel>
                                    <Autocomplete
                                        name="heelLift"
                                        id="heelLift" label="heelLift"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'heelLift', side: 'right', value }))}
                                        options={data.heelLift}
                                        value={insole.right.heelLift}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.heelLift}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.methodHeel}
                                >
                                    <FormLabel>Maakwijze hielpartij links</FormLabel>
                                    <Autocomplete
                                        name="methodHeel"
                                        id="methodHeel" label="methodHeel"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'methodHeel', side: 'left', value }))}
                                        options={data.methods}
                                        value={insole.left.methodHeel}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.methodHeel}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.methodHeelSize}
                                >
                                    <FormLabel>Hielpartij links (aantal mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={insole.left.methodHeelSize}
                                        error={error.left.methodHeelSize}
                                        onChange={event => dispatch(insoleData({ key: 'methodHeelSize', side: 'left', value: event.target.value }))}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.methodHeel}
                                >
                                    <FormLabel>Maakwijze hielpartij rechts</FormLabel>
                                    <Autocomplete
                                        name="methodHeel"
                                        id="methodHeel" label="methodHeel"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'methodHeel', side: 'right', value }))}
                                        options={data.methods}
                                        value={insole.right.methodHeel}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.methodHeel}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.methodFrontSize}
                                >
                                    <FormLabel>Hielpartij rechts (aantal mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={insole.right.methodFrontSize}
                                        error={error.right.methodFrontSize}
                                        onChange={event => dispatch(insoleData({ key: 'methodFrontSize', side: 'right', value: event.target.value }))}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.heelSpur}
                                >
                                    <FormLabel>Hielspoor*</FormLabel>
                                    <Autocomplete
                                        name="heelSpur"
                                        id="heelSpur" label="heelSpur"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'heelSpur', value }))}
                                        options={data.heelSpur}
                                        value={insole.heelSpur}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.heelSpur}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableguttersexpanded={`${expanded.includes('panel3')}`} onChange={(event) => handleChange('panel3')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Elementen midden/ teen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.transverseSupportMaterial}
                                >
                                    <FormLabel>Transversaalsteun materiaal links</FormLabel>
                                    <Autocomplete
                                        name="transverseSupportMaterial"
                                        id="transverseSupportMaterial" label="transverseSupportMaterial"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'transverseSupportMaterial', side: 'left', value }))}
                                        options={data.transverseSupportMaterial}
                                        value={insole.left.transverseSupportMaterial}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.transverseSupportMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.transverseSupport}
                                >
                                    <FormLabel>Transversaalsteun hoogte links*</FormLabel>
                                    <Autocomplete
                                        name="transverseSupport"
                                        id="transverseSupport" label="transverseSupport"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'transverseSupport', side: 'left', value }))}
                                        options={data.transverseSupport}
                                        value={insole.left.transverseSupport}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.transverseSupport}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.transverseSupportMaterial}
                                >
                                    <FormLabel>Transversaalsteun materiaal rechts</FormLabel>
                                    <Autocomplete
                                        name="transverseSupportMaterial"
                                        id="transverseSupportMaterial" label="transverseSupportMaterial"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'transverseSupportMaterial', side: 'right', value }))}
                                        options={data.transverseSupportMaterial}
                                        value={insole.right.transverseSupportMaterial}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.transverseSupportMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.transverseSupport}
                                >
                                    <FormLabel>Transversaalsteun hoogte rechts*</FormLabel>
                                    <Autocomplete
                                        name="transverseSupport"
                                        id="transverseSupport" label="transverseSupport"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'transverseSupport', side: 'right', value }))}
                                        options={data.transverseSupport}
                                        value={insole.right.transverseSupport}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.transverseSupportMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.pelot}
                                >
                                    <FormLabel>Pelotte links*</FormLabel>
                                    <Autocomplete
                                        name="pelot"
                                        id="pelot" label="pelot"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'pelot', side: 'left', value }))}
                                        options={data.pelot}
                                        value={insole.left.pelot}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.pelot}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.pelot}
                                >
                                    <FormLabel>Pelotte rechts*</FormLabel>
                                    <Autocomplete
                                        name="pelot"
                                        id="pelot" label="pelot"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'pelot', side: 'right', value }))}
                                        options={data.pelot}
                                        value={insole.right.pelot}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.pelot}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.toeBarMaterial}
                                >
                                    <FormLabel>Teendorpel materiaal links*</FormLabel>
                                    <Autocomplete
                                        name="toeBarMaterial"
                                        id="toeBarMaterial" label="toeBarMaterial"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'toeBarMaterial', side: 'left', value }))}
                                        options={data.toeBarMaterial}
                                        value={insole.left.toeBarMaterial}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.toeBarMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.toeBar}
                                >
                                    <FormLabel>Teendorpel hoogte links*</FormLabel>
                                    <Autocomplete
                                        name="toeBar"
                                        id="toeBar" label="toeBar"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'toeBar', side: 'left', value }))}
                                        options={data.toeBar}
                                        value={insole.left.toeBar}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.toeBar}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.toeBarMaterial}
                                >
                                    <FormLabel>Teendorpel materiaal rechts*</FormLabel>
                                    <Autocomplete
                                        name="toeBarMaterial"
                                        id="toeBarMaterial" label="toeBarMaterial"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'toeBarMaterial', side: 'right', value }))}
                                        options={data.toeBarMaterial}
                                        value={insole.right.toeBarMaterial}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.toeBarMaterial}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.toeBar}
                                >
                                    <FormLabel>Teendorpel hoogte rechts*</FormLabel>
                                    <Autocomplete
                                        name="toeBar"
                                        id="toeBar" label="toeBar"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'toeBar', side: 'right', value }))}
                                        options={data.toeBar}
                                        value={insole.right.toeBar}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.toeBar}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.methodFront}
                                >
                                    <FormLabel>Maakwijze voorvoet links</FormLabel>
                                    <Autocomplete
                                        name="methodFront"
                                        id="methodFront" label="methodFront"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'methodFront', side: 'left', value }))}
                                        options={data.methods}
                                        value={insole.left.methodFront}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.methodFront}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.methodFrontSize}
                                >
                                    <FormLabel>Voorvoet links (aantal mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        error={error.left.methodFrontSize}
                                        value={insole.left.methodFrontSize}
                                        onChange={event => dispatch(insoleData({ key: 'methodFrontSize', side: 'left', value: event.target.value }))} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.methodFront}
                                >
                                    <FormLabel>Maakwijze voorvoet rechts</FormLabel>
                                    <Autocomplete
                                        name="methodFront"
                                        id="methodFront" label="methodFront"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'methodFront', side: 'right', value }))}
                                        options={data.methods}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.methodFront}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.methodFrontSize}
                                >
                                    <FormLabel>Voorvoet rechts (aantal mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        error={error.right.methodFrontSize}
                                        value={insole.right.methodFrontSize}
                                        onChange={event => dispatch(insoleData({ key: 'methodFrontSize', side: 'right', value: event.target.value }))} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.methodFull}
                                >
                                    <FormLabel>Maakwijze geheel links</FormLabel>
                                    <Autocomplete
                                        name="methodFull"
                                        id="methodFull" label="methodFull"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'methodFull', side: 'left', value }))}
                                        options={data.methods}
                                        value={insole.left.methodFull}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.methodFull}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.methodFullSize}
                                >
                                    <FormLabel>Geheel links (aantal mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={insole.left.methodFullSize}
                                        error={error.left.methodFullSize}
                                        onChange={event => dispatch(insoleData({ key: 'methodFullSize', side: 'left', value: event.target.value }))}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.methodFull}
                                >
                                    <FormLabel>Maakwijze geheel rechts</FormLabel>
                                    <Autocomplete
                                        name="methodFull"
                                        id="methodFull" label="methodFull"
                                        onChange={(event, value) => dispatch(insoleData({ key: 'methodFull', side: 'right', value }))}
                                        options={data.methods}
                                        value={insole.right.methodFull}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.methodFull}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.methodFullSize}
                                >
                                    <FormLabel>Geheel rechts (aantal mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={insole.right.methodFullSize}
                                        error={error.right.methodFullSize}
                                        onChange={event => dispatch(insoleData({ key: 'methodFullSize', side: 'right', value: event.target.value }))} />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel4')} onChange={(event) => handleChange('panel4')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Afdek</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.coverMaterial}
                                    >
                                        <FormLabel>Bekleding*</FormLabel>
                                        <Autocomplete
                                            onChange={(event, value) => dispatch(insoleData({ key: 'coverMaterial', value }))}
                                            options={data.coverMaterial}
                                            value={insole.coverMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.coverMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking steunzool</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={insole.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(insoleData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(OSB)
