/**
 * validate if fields are not empty.
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateAllFields (state) {
    if (!state.sampleShoeAccordion.hasBeenOpened) return
    state.sampleShoeAccordion.validate = true
    // state.sampleShoeAccordion.validate = checkEmptyFields(state)
}

/**
 * Check if the fields of general are empty.
 *
 * @param {Object} state - redux state object
 * @returns {boolean} true
 */
function checkEmptyFields (state) {
    const sampleShoe = state.sampleShoeData
    const left = state.sampleShoeData.left
    const right = state.sampleShoeData.right

    state.sampleShoeErrors.left.anklePadding = equalOrNull(left.anklePadding)
    state.sampleShoeErrors.right.anklePadding = equalOrNull(right.anklePadding)
    state.sampleShoeErrors.anklePaddingMaterial = equalOrNull(sampleShoe.anklePaddingMaterial)
    state.sampleShoeErrors.left.contrefort = equalOrNull(left.contrefort)
    state.sampleShoeErrors.left.contrefortMaterial = equalOrNull(left.contrefortMaterial)
    state.sampleShoeErrors.right.contrefort = equalOrNull(right.contrefort)
    state.sampleShoeErrors.right.contrefortMaterial = equalOrNull(right.contrefortMaterial)

    state.sampleShoeErrors.left.nose = equalOrNull(left.nose)
    state.sampleShoeErrors.left.noseMaterial = equalOrNull(left.noseMaterial)
    state.sampleShoeErrors.right.nose = equalOrNull(right.nose)
    state.sampleShoeErrors.right.noseMaterial = equalOrNull(right.noseMaterial)

    state.sampleShoeErrors.left.heelHeight = equalOrNull(left.heelHeight)
    state.sampleShoeErrors.right.heelHeight = equalOrNull(right.heelHeight)

    state.sampleShoeErrors.left.entryPoint = equalOrNull(left.entryPoint)
    state.sampleShoeErrors.right.entryPoint = equalOrNull(right.entryPoint)

    state.sampleShoeErrors.left.upperHeight = equalOrNull(left.upperHeight)
    state.sampleShoeErrors.right.upperHeight = equalOrNull(right.upperHeight)

    state.sampleShoeErrors.left.soleSpace = equalOrNull(left.soleSpace)
    state.sampleShoeErrors.right.soleSpace = equalOrNull(right.soleSpace)

    state.sampleShoeErrors.left.soleStiffening = equalOrNull(left.soleStiffening)
    state.sampleShoeErrors.right.soleStiffening = equalOrNull(right.soleStiffening)

    state.sampleShoeErrors.left.heelRounding = equalOrNull(left.heelRounding)
    state.sampleShoeErrors.right.heelRounding = equalOrNull(right.heelRounding)

    return !equalOrNull(left.anklePadding) &&
    !equalOrNull(right.anklePadding) &&
    !equalOrNull(sampleShoe.anklePaddingMaterial) &&
    !equalOrNull(left.contrefort) &&
    !equalOrNull(left.contrefortMaterial) &&
    !equalOrNull(right.contrefort) &&
    !equalOrNull(right.contrefortMaterial) &&

    !equalOrNull(left.nose) &&
    !equalOrNull(left.noseMaterial) &&
    !equalOrNull(right.nose) &&
    !equalOrNull(right.noseMaterial) &&

    !equalOrNull(left.heelHeight) &&
    !equalOrNull(right.heelHeight) &&

    !equalOrNull(left.entryPoint) &&
    !equalOrNull(right.entryPoint) &&

    !equalOrNull(left.upperHeight) &&
    !equalOrNull(right.upperHeight) &&

    !equalOrNull(left.soleSpace) &&
    !equalOrNull(right.soleSpace) &&

    !equalOrNull(left.soleStiffening) &&
    !equalOrNull(right.soleStiffening) &&

    !equalOrNull(left.heelRounding) &&
    !equalOrNull(right.heelRounding)
}

/**
 * Check if a fields is an empty string, null or undefined.
 *
 * @param {string} value - value to check
 * @returns boolean with true or false
 */
function equalOrNull (value) {
    return value === '' || value === null || value === undefined
}
