import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields } from './validation.js'
import { validateMinMax } from '../genericFunctions/index.js'
import dataProvider from '../../../lib/dataProvider.js'

const data = dataProvider.osbOutsole

const osbOutsoleSlice = createSlice({
    name: 'osbOutsole',
    initialState,
    reducers: {
        osbOutsoleData (state, action) {
            const key = action.payload.key
            const value = action.payload.value === null ? '' : action.payload.value
            const side = action.payload.side || ''
            switch (key) {
            default:
                if (side === '') {
                    state.osbOutsoleData[key] = value
                } else {
                    if (side === 'left' && state.osbOutsoleData.left[key] === state.osbOutsoleData.right[key]) {
                        state.osbOutsoleData.left[key] = value
                        state.osbOutsoleData.right[key] = value
                    } else {
                        state.osbOutsoleData[side][key] = value
                    }
                }
                // check if theres a minMax value
                if (data[key] !== undefined && data[key].min !== undefined) {
                    validateMinMax(data[key], state, action, 'osbOutsoleErrors')
                }
                break
            }
        },
        osbOutsoleAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                if (!state.osbOutsoleAccordion.hasBeenOpened && state.osbOutsoleAccordion.accordion && !action.payload.value) {
                    state.osbOutsoleAccordion.hasBeenOpened = true
                }
                state.osbOutsoleAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.osbOutsoleAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.osbOutsoleAccordion.hasBeenOpened = true
                break
            }
        },
        osbOutsoleClose (state) {
            state.osbOutsoleAccordion.accordion = false
            state.osbOutsoleAccordion.hasBeenOpened = true
            validateAllFields(state)
        },
        importOsbOutsole (state, action) {
            state.osbOutsoleData = action.payload
        },
        resetState (state) {
            state.osbOutsoleData = initialState.osbOutsoleData
        }
    }
})

export const { resetState, osbOutsoleData, osbOutsoleAccordion, importOsbOutsole, osbOutsoleClose } = osbOutsoleSlice.actions
export default osbOutsoleSlice.reducer
