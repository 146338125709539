import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { osaFinishData, osaFinishAccordion } from '../../store/reducers/osaFinish'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'

const data = dataProvider.sampleShoe

const osaFinish = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const osaFinish = useSelector((state) => state.osaFinish.osaFinishData)
    const error = useSelector((state) => state.osaFinish.osaFinishErrors)
    const tab = useSelector((state) => state.osaFinish.osaFinishAccordion)

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(osaFinishAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>OSA Afwerking</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.left.transverseSupport}
                            >
                                <FormLabel>Transversaalsteun links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.transverseSupport}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'transverseSupport', side: 'left', value }))}
                                    options={data.transverseSupport}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.transverseSupport}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.left.transverseSupportHeight}
                            >
                                <FormLabel>Hoogte links (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    value={osaFinish.left.transverseSupportHeight}
                                    error={error.left.transverseSupportHeight}
                                    onChange={event => dispatch(osaFinishData({ key: 'transverseSupportHeight', side: 'left', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.transverseSupport}
                            >
                                <FormLabel>Transversaalsteun rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.transverseSupport}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'transverseSupport', side: 'left', value }))}
                                    options={data.transverseSupport}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.transverseSupport}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.transverseSupportHeight}
                            >
                                <FormLabel>Hoogte rechts (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    value={osaFinish.right.transverseSupportHeight}
                                    error={error.right.transverseSupportHeight}
                                    onChange={event => dispatch(osaFinishData({ key: 'transverseSupportHeight', side: 'right', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.left.pelot}
                            >
                                <FormLabel>Pelotte links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.pelot}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'pelot', side: 'left', value }))}
                                    options={data.pelot}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.pelot}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.left.pelotHeight}
                            >
                                <FormLabel>Hoogte links (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    value={osaFinish.left.pelotHeight}
                                    error={error.left.pelotHeight}
                                    onChange={event => dispatch(osaFinishData({ key: 'pelotHeight', side: 'left', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.pelot}
                            >
                                <FormLabel>Pelotte rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.pelot}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'pelot', side: 'left', value }))}
                                    options={data.pelot}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.pelot}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.pelotHeight}
                            >
                                <FormLabel>Hoogte rechts (mm)</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    value={osaFinish.right.pelotHeight}
                                    error={error.right.pelotHeight}
                                    onChange={event => dispatch(osaFinishData({ key: 'pelotHeight', side: 'right', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.distanceToHeel}
                            >
                                <FormLabel>Afstand tot hak links</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder='0'
                                    value={osaFinish.left.addedLength}
                                    error={error.left.addedLength}
                                    onChange={event => dispatch(osaFinishData({ key: 'distanceToHeel', side: 'left', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.distanceToHeel}
                            >
                                <FormLabel>Afstand tot hak rechts</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='number'
                                    placeholder='0'
                                    value={osaFinish.right.addedLength}
                                    error={error.right.addedLength}
                                    onChange={event => dispatch(osaFinishData({ key: 'distanceToHeel', side: 'right', value: event.target.value }))}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.left.toeTreshold}
                            >
                                <FormLabel>Teendorpel links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.toeTreshold}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'toeTreshold', side: 'left', value }))}
                                    options={data.toeTreshold}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.toeTreshold}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.left.toeTresholdMaterial}
                            >
                                <FormLabel>Teendorpel materiaal links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.toeTresholdMaterial}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'toeTresholdMaterial', side: 'left', value }))}
                                    options={data.toeTresholdMaterial}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.toeTresholdMaterial}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.toeTreshold}
                            >
                                <FormLabel>Teendorpel rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.toeTreshold}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'toeTreshold', side: 'right', value }))}
                                    options={data.toeTreshold}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.toeTreshold}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.toeTresholdMaterial}
                            >
                                <FormLabel>Teendorpel materiaal rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.toeTresholdMaterial}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'toeTresholdMaterial', side: 'right', value }))}
                                    options={data.toeTresholdMaterial}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.toeTresholdMaterial}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.left.heelCurb}
                            >
                                <FormLabel>Hielstoepje links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.heelCurb}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'heelCurb', side: 'left', value }))}
                                    options={data.heelCurb}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.heelCurb}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.heelCurbMaterial}
                            >
                                <FormLabel>Hielstoepje materiaal links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.heelCurbMaterial}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'heelCurbMaterial', side: 'left', value }))}
                                    options={data.heelCurbMaterial}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.heelCurbMaterial}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.heelCurb}
                            >
                                <FormLabel>Hielstoepje rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.heelCurb}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'heelCurb', side: 'right', value }))}
                                    options={data.heelCurb}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.heelCurb}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.right.heelCurbMaterial}
                            >
                                <FormLabel>Hielstoepje materiaal rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.heelCurbMaterial}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'heelCurbMaterial', side: 'right', value }))}
                                    options={data.heelCurbMaterial}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.heelCurbMaterial}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                            >
                                <FormControlLabel
                                    label="Uitdiepen tenen links"
                                    control={
                                        <Checkbox
                                            checked={osaFinish.left.toeCutout}
                                            onChange={event => dispatch(osaFinishData({ value: event.target.checked, side: 'left', key: 'toeCutout' }))} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                            >
                                <FormControlLabel
                                    label="Uitdiepen tenen rechts"
                                    control={
                                        <Checkbox
                                            checked={osaFinish.right.toeCutout}
                                            onChange={event => dispatch(osaFinishData({ value: event.target.checked, side: 'right', key: 'toeCutout' }))} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.toes}
                            >
                                <FormLabel>Tenen links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.toes}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'toes', side: 'left', value }))}
                                    options={data.toes}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.toes}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.toes}
                            >
                                <FormLabel>Tenen rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.toes}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'toes', side: 'right', value }))}
                                    options={data.toes}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.toes}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.front}
                            >
                                <FormLabel>Voor links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.front}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'front', side: 'left', value }))}
                                    options={data.front}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.front}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.front}
                            >
                                <FormLabel>Voor rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.front}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'front', side: 'right', value }))}
                                    options={data.front}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.front}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.heel}
                            >
                                <FormLabel>Hiel links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.heel}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'heel', side: 'left', value }))}
                                    options={data.heel}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.heel}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.heel}
                            >
                                <FormLabel>Hiel rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.heel}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'heel', side: 'right', value }))}
                                    options={data.heel}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.heel}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.complete}
                            >
                                <FormLabel>Geheel links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.complete}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'complete', side: 'left', value }))}
                                    options={data.complete}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.complete}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.complete}
                            >
                                <FormLabel>Geheel rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.complete}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'complete', side: 'right', value }))}
                                    options={data.complete}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.complete}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.finishingSoleStiffening}
                            >
                                <FormLabel>Zoolverstijving</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.finishingSoleStiffening}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'finishingSoleStiffening', side: 'left', value }))}
                                    options={data.finishingSoleStiffening}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.finishingSoleStiffening}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.finishingSoleStiffening}
                            >
                                <FormLabel>Zoolverstijving</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.finishingSoleStiffening}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'finishingSoleStiffening', side: 'right', value }))}
                                    options={data.finishingSoleStiffening}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.finishingSoleStiffening}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.left.coverMaterial}
                            >
                                <FormLabel>Afdekmateriaal links</FormLabel>
                                <Autocomplete
                                    value={osaFinish.left.coverMaterial}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'coverMaterial', side: 'left', value }))}
                                    options={data.coverMaterial}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.left.coverMaterial}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl
                                fullWidth
                                error={error.right.coverMaterial}
                            >
                                <FormLabel>Afdekmateriaal rechts</FormLabel>
                                <Autocomplete
                                    value={osaFinish.right.coverMaterial}
                                    onChange={(event, value) => dispatch(osaFinishData({ key: 'coverMaterial', side: 'right', value }))}
                                    options={data.coverMaterial}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.right.coverMaterial}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                    </Grid>

                </FormGroup>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking afwerking</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={osaFinish.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(osaFinishData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(osaFinish)
