export const supplier = [
    'Salih',
    'ZSM',
    'CYS',
    'Tom'
]

export const modelHeritage = [
    'Modellenboek',
    'Tekening',
    'Voorbeeldschoen',
    'Foto/plaatje',
    'Patroon'
]

export const collarPadding = [
    '-',
    'Links',
    'Rechts',
    'Beide'
]

export const collarPaddingThickness = [
    '3mm',
    '5mm',
    '8mm'
]

export const solePaddingThickness = [
    '3mm',
    '5mm'
]

export const collarPaddingType = [
    'Als foto',
    'Standaard'
]

export const solePadding = [
    '-',
    'Links',
    'Rechts',
    'Beide'
]

export const entryPoint = [
    'Standaard',
    '10 mm naar voren',
    '20 mm naar voren',
    '30 mm naar voren',
    'Lijn op de leest'
]

export const laces = [
    'Ringen',
    'Haken',
    'Triangels',
    'Lowahaken'
]

export const laceRings = [
    'Blind',
    'Fijn',
    'Grof',
    'Lowa'
]

export const laceHooks = [
    'Fijn',
    'Grof',
    'Lowa'
]

export const lining = [
    'Tot aan de bal',
    'Geheel'
]

export const developMethod = [
    'Gelijmd',
    'Doorgenaaid',
    'Flexibel'
]

export const mudguardLeather = [
    '-',
    'Bal-bal',
    'Hak-hak',
    'Rondom'
]

export const zipper = [
    'Als model',
    'Geen',
    'Mediaal',
    'Lateraal',
    'Mediaal en lateraal'
]

export const velcro = [
    'Smal',
    'Normaal',
    'Breed'
]
export const contrefort = [
    'Normaal',
    'Hoog lateraal',
    'Hoog mediaal',
    'Hoog-lateraal+mediaal',
    'Rondom',
    'Rondom+U',
    'Peroneus',
    'Maur'
]

export const liningMaterial = [
    'Standaard',
    'Antitransparant',
    'Foam',
    'Bont',
    'Durafit',
    'Voorbladvoering',
    'Herenvoering',
    'Bio/natura'
]

export const heelHeight = {
    min: 0,
    max: 50
}

export const upperHeight = {
    min: 0,
    max: 450
}

export const velcroAmount = { min: 0 }

export const buckle = [
    'Als model',
    'Geen'
]

export const elastic = [
    'Als model',
    'Geen'
]
