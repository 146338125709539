export const initialState = {
    osaLowerData: {
        developMethod: '',
        widthSole: '',
        insideSole: '',
        contrefortMaterial: '',
        anklePaddingMaterial: '',
        nose: '',
        noseMaterial: '',
        sideLining: '',
        edges: '',
        edgesMaterial: '',
        midSole: '',
        outSole: '',
        soleNumber: '',
        toeParts: '',
        heelMaterial: '',
        heelModel: '',
        mudguard: '',
        mudguardMaterial: '',
        heelStructure: '',
        heelBuffer: '',
        backflap: '',
        note: '',
        left: {
            cambering: '',
            contrefort: '',
            anklePadding: '',
            schoringCTLateral: '',
            schoringCTMedial: '',
            heelHeight: '',
            rocker: '',
            boardSize: '',
            woodenHeelType: '',
            schoringLat: '',
            schoringMed: '',
            rounding: ''
        },
        right: {
            cambering: '',
            contrefort: '',
            anklePadding: '',
            schoringCTLateral: '',
            schoringCTMedial: '',
            heelHeight: '',
            rocker: '',
            boardSize: '',
            woodenHeelType: '',
            schoringLat: '',
            schoringMed: '',
            rounding: ''
        }
    },
    osaLowerAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    osaLowerErrors: {
        message: [],
        developMethod: false,
        widthSole: false,
        insideSole: false,
        contrefortMaterial: false,
        anklePaddingMaterial: false,
        nose: false,
        noseMaterial: false,
        sideLining: false,
        edges: false,
        edgesMaterial: false,
        midSole: false,
        outSole: false,
        soleNumber: false,
        toeParts: false,
        heelMaterial: false,
        heelModel: false,
        mudguard: false,
        mudguardMaterial: false,
        heelStructure: false,
        heelBuffer: false,
        backflap: false,
        left: {
            lat: false,
            cambering: false,
            contrefort: false,
            anklePadding: false,
            schoringCTLateral: false,
            schoringCTMedial: false,
            heelHeight: false,
            rocker: false,
            boardSize: false,
            woodenHeelType: false,
            schoringLat: false,
            schoringMed: false,
            rounding: false
        },
        right: {
            lat: false,
            cambering: false,
            contrefort: false,
            anklePadding: false,
            schoringCTLateral: false,
            schoringCTMedial: false,
            heelHeight: false,
            rocker: false,
            boardSize: false,
            woodenHeelType: false,
            schoringLat: false,
            schoringMed: false,
            rounding: false
        }
    }
}
