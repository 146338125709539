/**
 * Validate minimum and maximum values according to dataprovider
 *
 * @param {Object} data - supplied data
 * @param {Object} state - current state
 * @param {Object} action - given change
 */
export function validateMinMax (data, state, action, errorKey) {
    const key = action.payload.key
    const value = action.payload.value === null ? '' : parseInt(action.payload.value)
    const side = action.payload.side || ''

    if (side === '') {
        if ((value > data.max || value < data.min) && value !== '') {
            state[errorKey][key] = true
        } else {
            state[errorKey][key] = false
        }
    } else {
        if ((value > data.max || value < data.min) && value !== '') {
            state[errorKey][side][key] = true
        } else {
            state[errorKey][side][key] = false
        }
    }
}
