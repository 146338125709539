import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields } from './validation.js'
import { validateMinMax } from '../genericFunctions/index.js'
import dataProvider from '../../../lib/dataProvider.js'

const data = dataProvider.sampleShoe

const sampleShoeSlice = createSlice({
    name: 'sampleShoe',
    initialState,
    reducers: {
        sampleShoeData (state, action) {
            const key = action.payload.key
            const value = action.payload.value === null ? '' : action.payload.value
            const side = action.payload.side || ''
            switch (key) {
            default:
                if (side === '') {
                    state.sampleShoeData[key] = value
                } else {
                    if (side === 'left' && state.sampleShoeData.left[key] === state.sampleShoeData.right[key]) {
                        state.sampleShoeData.left[key] = value
                        state.sampleShoeData.right[key] = value
                    } else {
                        state.sampleShoeData[side][key] = value
                    }
                }
                // check if theres a minMax value
                if (data[key] !== undefined && data[key].min !== undefined) {
                    validateMinMax(data[key], state, action, 'sampleShoeErrors')
                }
                break
            }
        },
        sampleShoeAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                if (!state.sampleShoeAccordion.hasBeenOpened && state.sampleShoeAccordion.accordion && !action.payload.value) {
                    state.sampleShoeAccordion.hasBeenOpened = true
                }

                state.sampleShoeAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.sampleShoeAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.sampleShoeAccordion.hasBeenOpened = true
                break
            }
        },
        sampleShoeClose (state) {
            state.sampleShoeAccordion.accordion = false
            state.sampleShoeAccordion.hasBeenOpened = true
            validateAllFields(state)
        },
        importSampleShoe (state, action) {
            state.sampleShoeData = action.payload
        },
        resetState (state) {
            state.sampleShoeData = initialState.sampleShoeData
        }
    }
})

export const { resetState, sampleShoeData, sampleShoeAccordion, importSampleShoe, sampleShoeClose } = sampleShoeSlice.actions
export default sampleShoeSlice.reducer
