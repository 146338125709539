import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './style'
import TherapistDisplay from '../../components/TherapistDisplay'
import NavigationButtons from '../../components/NavigationButtons'
import { withStyles } from '@mui/styles'
import General from '../../tabs/general'
import SampleShoe from '../../tabs/sampleShoe'
import OsaSupplement from '../../tabs/osaSupplement'
import { setOrderType } from '../../store/reducers/general'
import { useNavigate } from 'react-router-dom'
import ValidationErrorDialog from '../../components/ValidationErrorDialog'
import { sampleShoeData } from '../../store/reducers/sampleShoe'

const VLOSpage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const other = useSelector(state => state.other)

    useEffect(() => {
        if (other.allValid) {
            navigate('/overview')
        }
    }, [other.allValid, other.errorDialog])
    /**
     */
    useEffect(() => {
        dispatch(setOrderType('VLOS'))
        dispatch(sampleShoeData({ key: 'footwearType', side: 'left', value: 'VLOS' }))
        dispatch(sampleShoeData({ key: 'footwearType', side: 'right', value: 'VLOS' }))
    }, [])

    return (
        <div>
            <TherapistDisplay />
            <NavigationButtons />
            <General />
            <OsaSupplement/>
            <SampleShoe/>
            <ValidationErrorDialog />
            <NavigationButtons />
        </div>
    )
}
export default withStyles(useStyles)(VLOSpage)
