export const initialState = {
    insoleData: {
        developMethod: '',
        insoleType: '',
        stiffness: '',
        soleSpecification: '',
        soleMaterial: '',
        coverMaterial: '',
        heelSpur: '',
        note: '',
        left: {
            correction: '',
            correctionHeight: '',
            heelLift: '',
            transverseSupport: '',
            transverseSupportMaterial: '',
            pelot: '',
            toeBar: '',
            toeBarMaterial: '',
            methodFront: '',
            methodFrontSize: '',
            methodHeel: '',
            methodHeelSize: '',
            methodFull: '',
            methodFullSize: ''
        },
        right: {
            correction: '',
            correctionHeight: '',
            heelLift: '',
            transverseSupport: '',
            transverseSupportMaterial: '',
            pelot: '',
            toeBar: '',
            toeBarMaterial: '',
            methodFront: '',
            methodFrontSize: '',
            methodHeel: '',
            methodHeelSize: '',
            methodFull: '',
            methodFullSize: ''
        }
    },
    insoleAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    insoleErrors: {
        message: [],
        developMethod: false,
        insoleType: false,
        stiffness: false,
        soleSpecification: false,
        soleMaterial: false,
        coverMaterial: false,
        heelSpur: false,
        left: {
            correction: false,
            correctionHeight: false,
            heelLift: false,
            transverseSupport: false,
            transverseSupportMaterial: false,
            pelot: false,
            toeBar: false,
            toeBarMaterial: false,
            methodFront: false,
            methodFrontSize: false,
            methodHeel: false,
            methodHeelSize: false,
            methodFull: false,
            methodFullSize: false
        },
        right: {
            correction: false,
            correctionHeight: false,
            heelLift: false,
            transverseSupport: false,
            transverseSupportMaterial: false,
            pelot: false,
            toeBar: false,
            toeBarMaterial: false,
            methodFront: false,
            methodFrontSize: false,
            methodHeel: false,
            methodHeelSize: false,
            methodFull: false,
            methodFullSize: false
        }
    }
}
