/**
 * validate if fields are not empty.
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateAllFields (state) {
    if (!state.repairAccordion.hasBeenOpened) return
    state.repairAccordion.validate = true
}
