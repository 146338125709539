export const footwearType = [
    'Passchoen leer',
    'Voeringsschoen',
    'Tongsupplement',
    'Pantoffel',
    'Voering'
]

export const lining = [
    'Tot aan de bal',
    'Geheel'
]

export const liningMaterial = [
    'Standaard',
    'Antitransparant',
    'Foam',
    'Bont',
    'Durafit',
    'Voorbladvoering'
]

export const tonguePadMaterial = [
    '-',
    'PPT',
    'Lunairmed',
    'Multiform',
    'XRD'
]

export const tongueReinforcement = [
    '-',
    'Tepp',
    'Ercoflex 2 mm'
]

export const anklePadding = [
    'Geheel',
    'Alleen enkel',
    'Enkels extra',
    'Polsterkraag'
]

export const anklePaddingMaterial = [
    'PPT',
    'Lunairmed',
    'XRD'
]

export const contrefort = [
    'Normaal',
    'Hoog lateraal',
    'Hoog mediaal',
    'Hoog-lateraal+mediaal',
    'Rondom',
    'Rondom+U',
    'Peroneus',
    'Maur'
]

export const contrefortMaterial = [
    'Ercoflex',
    'Max',
    'Max + Thermite',
    'Corrit',
    'Renoflex',
    'Thermite',
    'Koolstof geleng',
    'Leer',
    'M.O. Dun',
    'M.O. Dik',
    'Leer + Ercoflex',
    'Maur',
    'Multiform + Renoflex'
]

export const schoring = [
    '0,5 lat',
    '1,0 lat',
    '1,5 lat',
    '2,0 lat',
    '2,5 lat',
    '3,0 lat',
    '0,5 med',
    '1,0 med',
    '1,5 med',
    '2,0 med',
    '2,5 med',
    '3,0 med'
]

export const nose = [
    'Normaal',
    'Uitgehold'
]
export const noseMaterial = [
    '-',
    'Leer',
    'Kunstof',
    'Diabetisch'
]

export const heelBuffer = [
    '-',
    'Links',
    'Rechts',
    'Beide'
]

export const entryPoint = [
    'Standaard',
    '10 mm naar voren',
    '10 mm naar achteren',
    'Lijn op de leest'
]

export const soleSpace = [
    '-',
    '3mm',
    '5mm'
]

export const soleStiffening = [
    'Carbon dik',
    'Carbon dun',
    'Carbon gelamineerd',
    'MO'
]

export const closingMechanism = [
    'Haakband',
    'Haken en ringen',
    'Riem',
    'Rits',
    'Klittenband'
]

export const transverseSupport = [
    '-',
    'Rubberkurk',
    'Flexor',
    'Groen',
    'Wim Majoor',
    'Multiform'
]

export const pelot = [
    'Klein',
    'Middel',
    'Groot',
    'T-pelot'
]

export const toeTreshold = [
    '-',
    '3mm',
    '5mm'
]

export const toeTresholdMaterial = [
    'Multiform',
    'Rubberkurk'
]

export const heelCurb = [
    '-',
    '3mm',
    '5mm'
]
export const heelCurbMaterial = [
    'Multiform',
    'Rubberkurk'
]

export const insideSole = [
    'Leer',
    'Kunstof'
]

export const toes = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const front = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const heel = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const complete = [
    'PPT',
    'Schuimrubber',
    'Sandwich',
    'Siliconen',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Rode PPT'
]

export const finishingSoleStiffening = [
    'Carbon dik',
    'Carbon dun',
    'MO',
    'Zie aftekening supplement'
]

export const coverMaterial = [
    'Standaard',
    'Anti-transparant',
    'Plastazote 3 mm',
    'Diafoam zwart',
    'Diafoam blauw',
    'Sandwich',
    'Zacht leer',
    'Geit',
    'Witte damesvoering'
]

export const upperHeight = {
    min: 0,
    max: 40
}

export const heelHeight = {
    min: 0,
    max: 50
}

export const heelRounding = { min: 0 }
export const soleElevationHeel = { min: 0 }
export const soleElevationBall = { min: 0 }
export const soleElevationToe = { min: 0 }
export const addedLength = { min: 0 }
