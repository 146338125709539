/**
 * validate if fields are not empty.
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateAllFields (state) {
    if (!state.osbShoeAccordion.hasBeenOpened) return
    state.osbShoeAccordion.validate = true
}
