export const rocker = [
    'Afwikkelbalk',
    'Versnelde afwikkeling',
    'Vertraagde afwikkeling',
    '2-fasen',
    'Polyfasische afwikkeling'
]

export const correction = [
    'Geheel proneren',
    'Geheel supineren',
    'Voor supineren',
    'Achter supineren',
    'Voor proneren',
    'Achter proneren',
    'Torsie',
    'Detorsie',
    '-'
]

export const correctionHeight = [
    '3mm',
    '5mm'
]

export const solePad = [
    '3mm',
    '5mm'
]

export const stretch = [
    'Hallux mediaal',
    'Hallux dorsaal',
    'MTP V',
    'Geheel',
    'MTP regio',
    'Teenbox',
    'Haglundse exostose'
]

export const closureAdjust = [
    'Veters in plaats van klittenband',
    'Klittenband in plaats van veters',
    'Rits mediaal',
    'Rits lateraal',
    'Haken in plaats van ringen'
]

export const soleStiffening = [
    '-',
    'Carbon dik',
    'Carbon dun'
]

export const openPosition = { min: 0 }
export const lowerUpper = { min: 0 }
export const heel = { min: 0 }
export const ball = { min: 0 }
export const toe = { min: 0 }
export const lateral = { min: 0 }
export const medial = { min: 0 }
