import { createStyles } from '@mui/styles'

const styles = createStyles((theme) => ({
    paper: {
        height: 250,
        cursor: 'pointer',
        textAlign: 'center',
        overflow: 'hidden',
        borderRadius: 20,
        position: 'relative',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
    },
    fitsComfortPaper: {
        background: '#ff0022'
    },
    primary: {
        background: theme.palette.primary.box
    },
    secondary: {
        background: theme.palette.tertiary.box
    },
    tertiary: {
        background: theme.palette.secondary.box
    },
    svgPaper: {
        maxWidth: 100,
        maxHeight: 100,
        marginBottom: 30
    },
    paperText: {
        marginTop: theme.spacing(3),
        color: '#ffffff'
    },
    version: {
        marginTop: theme.spacing(14)
    },
    versionText: {
        color: '#9d9c9c'
    }
}))

export default styles
