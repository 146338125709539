import React, { useEffect, useRef, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Input,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    Select,
    Box,
    MenuItem,
    InputBase,
    Tooltip,
    Autocomplete
} from '@mui/material'
import { ExpandMore, Search } from '@mui/icons-material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useSelector, useDispatch } from 'react-redux'
import { patientData, generalAccordion, setError, resetPatientData } from '../../store/reducers/general'

import useStyles from './style'
import api from '../../lib/api'
import moment from 'moment'
import { withStyles } from '@mui/styles'
import { setPreviousOrders } from '../../store/reducers/other'
import { importInsole, resetState as resetInsole } from '../../store/reducers/insole'
import { importOsaFinish, resetState as resetOsaFinish } from '../../store/reducers/osaFinish'
import { importOsaLower, resetState as resetOsaLower } from '../../store/reducers/osaLower'
import { importOsaPattern, resetState as resetOsaPattern } from '../../store/reducers/osaPattern'
import { importOsaSupplement, resetState as resetOsaSupplement } from '../../store/reducers/osaSupplement'
import { importOsbShoe, resetState as resetOsbShoe } from '../../store/reducers/osb'
import { importOsbOutsole, resetState as resetOsbOutsole } from '../../store/reducers/osbOutsole'
import { importRepair, resetState as resetRepair } from '../../store/reducers/repair'
import { importSampleShoe, resetState as resetSampleShoe } from '../../store/reducers/sampleShoe'

const General = ({ classes }) => {
    const locationsListRef = useRef([])
    const [locationsNamesRef, setLocationsNamesRef] = useState([])
    const [hasDateSet, setDate] = useState(false)
    const previousOrders = useSelector((state) => state.other.previousOrders)

    // state management
    const dispatch = useDispatch()
    const patient = useSelector((state) => state.general.patientData)
    const orderType = useSelector((state) => state.general.orderType)
    const error = useSelector((state) => state.general.generalErrors)
    const tab = useSelector((state) => state.general.generalAccordion)
    const [selectedPreviousOrder, setSelectedPreviousOrder] = useState('Geen')

    useEffect(() => {
        if (!tab.accordion) {
            toggleAccordion()
            dispatch(generalAccordion({ key: 'validateFalse' }))
        }

        api.location((rsp) => {
            locationsListRef.current = rsp
            setLocationsNamesRef(rsp.map(x => x.title))
        }, (error) => {
            dispatch(setError(error))
        })

        if (!hasDateSet) {
            onDateSelected(moment(), 'deliveryDate')
            setDate(true)
        }
    }, [])

    /**
     *
     */
    function importOrder (event) {
        dispatch(resetInsole())
        dispatch(resetOsaFinish())
        dispatch(resetOsaLower())
        dispatch(resetOsaPattern())
        dispatch(resetOsaSupplement())
        dispatch(resetOsbOutsole())
        dispatch(resetOsbShoe())
        dispatch(resetRepair())
        dispatch(resetSampleShoe())
        setSelectedPreviousOrder(event)

        switch (event.orderType) {
        case 'Proefschoen':
            dispatch(importSampleShoe(event.sampleShoeData))
            break
        case 'VLOS':
            dispatch(importOsaSupplement(event.osaSupplementData))
            dispatch(importSampleShoe(event.sampleShoeData))
            break
        case 'OSA':
            dispatch(importOsaPattern(event.osaPatternData))
            dispatch(importOsaLower(event.osaLowerData))
            dispatch(importOsaFinish(event.osaFinishData))
            break
        case 'Passchoen':
            dispatch(importOsaSupplement(event.osaSupplementData))
            break
        case 'Steunzool':
            dispatch(importInsole(event.insoleData))
            break
        case 'OSB':
            dispatch(importOsbShoe(event.osbShoeData))
            dispatch(importInsole(event.insoleData))
            dispatch(importOsbOutsole(event.osbOutsoleData))
            break
        case 'OVAC':
            dispatch(importOsbOutsole(event.osbOutsoleData))
            break
        case 'Reparatie':
            dispatch(importRepair(event.repairData))
            break
        }
    }

    /**
     * Change the patient number.
     * On input of the patient number id this function if called searching for patients
     *
     * @param {Object} newInputValue the new input value
     * @returns
     */
    function onPatientNumberInput (patientNumber) {
        dispatch(patientData({ key: 'number', value: patientNumber }))
    }

    /**
     * Update the date in the ui.
     *
     * @param {Date} date - date to update to.
     */
    function onDateSelected (date, key) {
        const bday = moment(date).add(2, 'h')
        dispatch(patientData({ key, value: date === null ? '' : bday }))
    }

    /**
     * Change the lastname of the patient.
     *
     * @param {String} lastName - value of the last name.
     */
    function onLastNameChanged (lastName) {
        dispatch(patientData({ key: 'lastName', value: lastName.replace(/\d+/g, '') }))
    }

    /**
     * Change the fitting location.
     *
     * @param selectedLocation
     */
    function onFittingLocationSelected (selectedLocation) {
        if (!selectedLocation) {
            dispatch(patientData({ key: 'fittingLocation', value: { id: 0, name: '', title: '', placeName: '' } }))
        } else {
            const selectedLocationObj = locationsListRef.current.find(location => location.title.toLowerCase() === selectedLocation.toLowerCase())
            selectedLocationObj.id = parseInt(selectedLocationObj.id)
            dispatch(patientData({ key: 'fittingLocation', value: selectedLocationObj }))
            dispatch(patientData({ key: 'receiveLocation', value: selectedLocationObj }))
        }
    }

    /**
     * Update second location.
     *
     * @param selectedLocation
     */
    function onReceiveLocationSelected (selectedLocation) {
        if (!selectedLocation) {
            dispatch(patientData({ key: 'receiveLocation', value: { id: 0, name: '', title: '', placeName: '' } }))
        } else {
            const selectedLocationObj = locationsListRef.current.find(location => location.title.toLowerCase() === selectedLocation.toLowerCase())
            selectedLocationObj.id = parseInt(selectedLocationObj.id)
            dispatch(patientData({ key: 'receiveLocation', value: selectedLocationObj }))
        }
    }

    /**
     * Get patient data on request
     *
     * @returns null
     */
    function getPatientInfo () {
        api.patients(patient.number,
            (data) => {
                const bday = moment(data.birthDate).add(2, 'h')
                const lasts = [{ formattedName: '-' }]
                data.lasts.forEach(element => {
                    if (element.removed === null) {
                        lasts.push(element)
                    }
                })
                dispatch(patientData({ key: 'number', value: patient.number }))
                dispatch(patientData({ key: 'lastName', value: data.lastName }))
                dispatch(patientData({ key: 'birthdate', value: bday }))
                dispatch(patientData({ key: 'gender', value: data.sex.toLowerCase() }))
                dispatch(patientData({ key: 'id', value: `${data.id}` }))
                dispatch(patientData({ key: 'initials', value: `${data.initials}` }))
                dispatch(patientData({ key: 'activeLasts', value: lasts }))
                if (lasts.length === 2) {
                    dispatch(patientData({ key: 'selectedLast', value: lasts[1].formattedName }))
                }

                api.getOrder(data.id, orderType.toLowerCase(), (data) => {
                    const parsedData = []
                    data.forEach(element => {
                        parsedData.push(JSON.parse(element))
                    })
                    const orders = parsedData.sort((a, b) => b.senddate - a.senddate)
                    dispatch(setPreviousOrders(orders))
                }, (error) => {
                    dispatch(setError(error))
                })
            }, (error) => {
                dispatch(setError(error))
                dispatch(resetPatientData())
            })
    }

    /**
     * Change value of gender.
     *
     * @param {Object} gender - Event value.
     */
    function onPatientGenderSelected (gender) {
        dispatch(patientData({ key: 'gender', value: gender }))
    }

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(generalAccordion({ key: 'accordion', value: false }))
        dispatch(generalAccordion({ key: 'accordion', value: true }))
    }

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Algemeen</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
                <FormGroup>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.number}
                                variant="standard"
                            >
                                <FormLabel>Patiëntnummer*</FormLabel>
                                <Tooltip title="Druk 'enter' om te zoeken">
                                    <div className={classes.search}>
                                        <Box onClick={() => { alert('zoeken') }} className={classes.searchIcon}>
                                            <Search />
                                        </Box>
                                        <InputBase
                                            placeholder="Zoeken..."
                                            className={classes.inputInput}
                                            value={patient.number}
                                            onKeyDownCapture={(event) => event.key === 'Enter' && getPatientInfo()}
                                            onChange={event => onPatientNumberInput(event.target.value)}
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                    </div>
                                </Tooltip>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                            >
                                <FormLabel>Leest nummer</FormLabel>
                                <Select
                                    value={patient.selectedLast}
                                    className={classes.autoComplete}
                                    onChange={(event) => dispatch(patientData({ key: 'selectedLast', value: event.target.value }))}
                                >
                                    {
                                        patient.activeLasts.map((last, index) =>
                                            <MenuItem key={index} value={last.formattedName}>{last.formattedName}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid><Grid item xs={4}>
                            <FormControl
                                fullWidth
                            >
                                <FormLabel>Vorige bestelling</FormLabel>
                                <Select
                                    value={selectedPreviousOrder}
                                    onChange={event => importOrder(event.target.value)}
                                    className={classes.autoComplete}
                                >
                                    <MenuItem key={-1} value={'Geen'}>Geen</MenuItem>
                                    {
                                        previousOrders.map((order, index) =>
                                            <MenuItem
                                                key={index}
                                                value={order}>Datum: {order.sendDate !== undefined ? moment(order.sendDate).format('DD-MM-yyyy') : order.fileName.split('_')[2].split('.')[0]} {order.orderType}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.birthdate}
                            >
                                <FormLabel className={classes.formLabel}>Geboortedatum patiënt*</FormLabel>
                                <LocalizationProvider
                                    className={classes.autoComplete}dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        error={error.birthdate}
                                        value={patient.birthdate || Date.now('DD/MM/YYYYD')}
                                        onChange={(date) => onDateSelected(date, 'birthdate')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.gender}
                            >
                                <FormLabel>Geslacht patiënt*</FormLabel>
                                <Grid >
                                    <RadioGroup
                                        className={classes.autoComplete} row value={patient.gender} aria-label="gender" name="gender-select"
                                        onChange={(event) => onPatientGenderSelected(event.target.value)}>
                                        <FormControlLabel value="male" control={<Radio />} label="Man" />
                                        <FormControlLabel value="female" control={<Radio />} label="Vrouw" />
                                    </RadioGroup>
                                </Grid>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <FormControl
                                fullWidth
                                error={error.lastName}
                            >
                                <FormLabel>Initialen</FormLabel>
                                <Box className={classes.inputField}></Box>
                                <Input
                                    type='text' value={patient.initials}
                                    disabled={true}/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.lastName}
                            >
                                <FormLabel>Achternaam patiënt*</FormLabel>
                                <Box className={classes.inputField}></Box>
                                <Input
                                    type='text' value={patient.lastName}
                                    disabled={patient.number !== ''}
                                    onChange={event => onLastNameChanged(event.target.value)} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.fittingLocation}
                            >
                                <FormLabel>Aanmeetlocatie*</FormLabel>
                                <Autocomplete
                                    name="aanmeet-locatie"
                                    id="locatie" label="locatie"
                                    value={patient.fittingLocation.title}
                                    onChange={(event, value) => onFittingLocationSelected(value)}
                                    options={locationsNamesRef}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.fittingLocation}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.receiveLocation}
                            >
                                <FormLabel>Afleverlocatie*</FormLabel>
                                <Autocomplete
                                    name="Awfijkende locatie" // Check if Ontvangstlocatie Name/ID is used elsewhere.
                                    id="afwijkende"
                                    value={patient.receiveLocation.title}
                                    onChange={(event, value) => onReceiveLocationSelected(value)}
                                    options={locationsNamesRef}
                                    className={classes.autoComplete}
                                    renderInput={(params) =>
                                        <TextField
                                            error={error.receiveLocation}
                                            {...params} />
                                    } />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl
                                fullWidth
                                error={error.orderNumber}
                            >
                                <FormLabel>Ordernummer*</FormLabel>
                                <Box className={classes.autoComplete}></Box>
                                <TextField
                                    type='text'
                                    error={error.orderNumber}
                                    value={patient.orderNumber}
                                    onChange={event => dispatch(patientData({ key: 'orderNumber', value: event.target.value }))} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                            >
                                <FormLabel className={classes.formLabel}>Invoerdatum</FormLabel>
                                <LocalizationProvider
                                    className={classes.autoComplete}dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        disabled
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        value={patient.entryDate}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.deliveryDate}
                            >
                                <FormLabel className={classes.formLabel}>Aanleverdatum werkplaats*</FormLabel>
                                <LocalizationProvider
                                    className={classes.autoComplete} dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        error={error.deliveryDate}
                                        value={patient.deliveryDate}
                                        onChange={(date) => onDateSelected(date, 'deliveryDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.readyDate}
                            >
                                <FormLabel className={classes.formLabel}>Datum gereed*</FormLabel>
                                <LocalizationProvider
                                    className={classes.autoComplete} dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        error={error.readyDate}
                                        value={patient.readyDate}
                                        onChange={(date) => onDateSelected(date, 'readyDate')}
                                        disabled={orderType !== 'OSB' && orderType !== 'VLOS' && orderType !== 'Reparatie' && orderType !== 'OVAC'}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl
                                fullWidth
                                error={error.appointmentDate}
                            >
                                <FormLabel className={classes.formLabel}>Afspraak datum*</FormLabel>
                                <LocalizationProvider
                                    className={classes.autoComplete} dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        variant="inline"
                                        format="DD/MM/YYYY"
                                        error={error.appointmentDate}
                                        value={patient.appointmentDate}
                                        onChange={(date) => onDateSelected(date, 'appointmentDate')}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date'
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormGroup>
                {/* </MuiPickersUtilsProvider> */}
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(General)
