import Keycloak from 'keycloak-js'
import { IDENTITY_REALM, IDENTITY_CLIENT, IDENTITY_URL } from '../env'

const _kc = new Keycloak({
    url: `${IDENTITY_URL}/auth`,
    realm: IDENTITY_REALM,
    clientId: IDENTITY_CLIENT
})

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param {Function} onAuthenticatedCallback - callback when successful
 */
function initKeycloak (onAuthenticatedCallback, errorCallback) {
    if (_kc.token) return
    _kc.init({
        onLoad: 'check-sso',
        checkLoginIframe: false
    })
        .then((authenticated) => {
            if (!authenticated) {
                console.log('user is not authenticated..!')
                doLogin()
            }
            onAuthenticatedCallback()
        })
        .catch((error) => {
            console.error(error)
            errorCallback(error)
        })
}

/**
 * Login keycloak
 */
function doLogin () {
    _kc.login({ redirectUri: window.location.href })
}

/**
 * logout keycloak
 */
function doLogout () {
    _kc.logout()
}

/**
 * logout keycloak
 */
function getBackend () {
    return JSON.parse(_kc.tokenParsed?.backend)
}

/**
 * logout keycloak
 */
function getEnvironments () {
    if (_kc.tokenParsed?.environments === undefined) return []
    // else if (_kc.tokenParsed?.environments === undefined && _kc.tokenParsed?.backend !== undefined) {
    //     const value = _kc.tokenParsed?.backend.endpoint
    //     const type = _kc.tokenParsed?.backend.type
    //     const splittedUrl = value.split('//')[1].split('.')
    //     return [
    //         {
    //             key: `${splittedUrl[0]}_${splittedUrl[1]}`,
    //             value,
    //             type
    //         }
    //     ]
    // }
    // old style of environments {"key":"Podo-IT Test", "type":"podoit","value":"https://test.podo-it.nl"}

    const environments = []
    _kc.tokenParsed?.environments.forEach(element => {
        environments.push(typeof element === 'object' ? element : JSON.parse(element))
    })
    return environments
}

/**
 * retrieve token
 */
function getToken () {
    return _kc.token
}

/**
 * Check if is logged in.
 */
function isLoggedIn () {
    return _kc.token !== undefined
}

/**
 * update the token
 *
 * @param {Function} successCallback - callback when successful
 */
function updateToken (successCallback) {
    _kc.updateToken(600)
        .then(successCallback)
        .catch(doLogin)
}
/**
 * Retrieve username from keycloak
 *
 * @returns {String} the preffered username
 */
function getUsername () {
    return _kc.tokenParsed?.preferred_username
}

/**
 * Find a role in the user profile
 *
 * @param {String} searchRole - the role to search
 * @return {Boolean} true if the role is found
 */
function hasRole (searchRole) {
    if (!_kc.realmAccess) {
        return false
    }
    return _kc.realmAccess.roles.includes(searchRole)
}

const UserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    getBackend,
    getEnvironments,
    _kc
}

export default UserService
