import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields } from './validation.js'
import { validateMinMax } from '../genericFunctions/index.js'
import dataProvider from '../../../lib/dataProvider.js'

const data = dataProvider.repair

const repairSlice = createSlice({
    name: 'repair',
    initialState,
    reducers: {
        repairData (state, action) {
            const key = action.payload.key
            const value = action.payload.value === null ? '' : action.payload.value
            const side = action.payload.side || ''
            switch (key) {
            default:
                if (side === '') {
                    state.repairData[key] = value
                } else {
                    if (side === 'left' && state.repairData.left[key] === state.repairData.right[key]) {
                        state.repairData.left[key] = value
                        state.repairData.right[key] = value
                    } else {
                        state.repairData[side][key] = value
                    }
                }
                // check if theres a minMax value
                if (data[key] !== undefined && data[key].min !== undefined) {
                    validateMinMax(data[key], state, action, 'repairErrors')
                }
                break
            }
        },
        repairAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                if (!state.repairAccordion.hasBeenOpened && state.repairAccordion.accordion && !action.payload.value) {
                    state.repairAccordion.hasBeenOpened = true
                }

                state.repairAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.repairAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.repairAccordion.hasBeenOpened = true
                break
            }
        },
        repairClose (state) {
            state.repairAccordion.accordion = false
            state.repairAccordion.hasBeenOpened = true
            validateAllFields(state)
        },
        importRepair (state, action) {
            state.repairData = action.payload
        },
        resetState (state) {
            state.repairData = initialState.repairData
        }
    }
})

export const { resetState, repairData, repairAccordion, importRepair, repairClose } = repairSlice.actions
export default repairSlice.reducer
