import { createTheme } from '@mui/material'
import robotoRegular from '../assets/Roboto-Regular.ttf'
import robotoLight from '../assets/Roboto-Thin.ttf'
// import UserService from './userService'

class ThemeProvider {
    /**
     * Constructor.
     * @version 1.0.0
     */
    constructor () {
        this.type = 'light'
        this.backendKey = 'vcw_podo-it'
    }

    /**
     * Get vcw theme
     *
     * @returns {Object} a palette with theme colors
     */
    vcw () {
        return {
            mode: this.type,
            primary: {
                main: '#0a54bf',
                box: '#0a54bf',
                contrastText: '#fff'
            },
            secondary: {
                main: '#bb33bb',
                box: '#bb33bb',
                contrastText: '#fff'
            },
            tertiary: {
                main: '#7146be',
                box: '#7146be',
                contrastText: '#fff'
            },
            error: {
                main: '#FF4444',
                light: '#FFCFCF'
            },
            success: {
                main: '#23B956',
                light: '#CFFFAA'
            }
        }
    }

    /**
     * set theme type
     *
     * @param {String} newType - theme type, light or dark
     */
    setType (newType) {
        this.type = newType
    }

    /**
     * Get the correct palette
     *
     * @returns a pallete
     */
    palette (backend) {
        if (backend.key === 'Voetmax') return this.voetmax()
        if (backend.key === 'Penders') return this.penders()
        if (backend.key === 'Kievit Orthopedie') return this.kievitOrtho()
        // if (backend.key === 'test_podo-it') return this.voetmax()
        return this.vcw()
    }

    /**
     * Get the correct palette
     *
     * @returns a pallete
     */
    theme (backend) {
        // special for koen
        // if (process.env.NODE_ENV !== 'production') {
        //     this.type = 'dark'
        // }
        const theme = createTheme({
            typography: {
                fontFamily: 'Roboto, Helvetica Neue, sans-serif'
            },
            components: {
                MuiCssBaseline: {
                    styleOverrides: `
                    @font-face {
                      font-family: 'Roboto';
                      src: url(${robotoRegular});
                    }
                    @font-face {
                      font-family: 'Roboto-Light';
                      src: url(${robotoLight});
                    }
                  `
                }
            },
            palette: this.palette(backend)
        })
        return theme
    }
}

// eslint-disable-next-line new-cap
export default new ThemeProvider()
