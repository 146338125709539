export const material = [
    'Standaard-RX',
    'Standaard+RX',
    'Podo voorvoet-RX',
    'Podo voorvoet+RX',
    'Podo generaal -RX',
    'Podo generaal + RX',
    'Podo roze 1cm -RX',
    'Podo roze 1cm+RX',
    'Compleet multiform'
]

export const rocker = [
    'Standaard',
    '2-fasen',
    'Kunstmatig',
    'Vertraagde afwikkeling',
    'Vroege kunst',
    'Afwikkeling over lateraal',
    'Afwikkeling over mediaal',
    'Spitsvoet weinig afwikkeling nodig',
    'Polyfasische afwikkeling'
]

export const schoring = [
    '0,5 lat',
    '1,0 lat',
    '1,5 lat',
    '0,5 med',
    '1,0 med',
    '1,5 med'
]

export const fittingShoe = [
    'Geen folieschoen',
    'Lage folieschoen',
    'Hoge folieschoen',
    'Folieleest',
    'Lage folieschoen + folieleest',
    'Hoge folieschoen + folieleest',
    'Tongsupplement'
]

export const secondSupplement = [
    '-',
    'Compleet multiform',
    'Werksupplement'
]

export const version = [
    'Standaard',
    'Ezelsoor lateraal',
    'Ezelsoor mediaal',
    'Ezelsoor mediaal, keerwand buiten',
    'Ezelsoor mediaal en lateraal',
    'Keerwand buiten',
    'Keerwand binnen',
    'Keerwand buiten Corrit',
    'Kuipvoetbed',
    'Tongsupplement'
]
export const heelHeight = {
    min: 0,
    max: 50
}

export const heelThickness = { min: 0 }
export const toeThickness = { min: 0 }
export const waist = { min: 0 }
export const ballThicknessMedial = { min: 0 }
export const ballThicknessLateral = { min: 0 }
