import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    FormControlLabel,
    Checkbox,
    styled,
    Box
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { sampleShoeData, sampleShoeAccordion } from '../../store/reducers/sampleShoe'

import useStyles from './style'
import { withStyles } from '@mui/styles'

import dataProvider from '../../lib/dataProvider'
import { deepCopy } from '../../lib/util'

const data = dataProvider.sampleShoe

const sampleShoe = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const sampleShoe = useSelector((state) => state.sampleShoe.sampleShoeData)
    const error = useSelector((state) => state.sampleShoe.sampleShoeErrors)
    const tab = useSelector((state) => state.sampleShoe.sampleShoeAccordion)
    const orderType = useSelector((state) => state.general.orderType)
    const [expanded, setExpanded] = React.useState(['panel1'])

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }
    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(sampleShoeAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Voering-/ Proefschoen</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Schoen eigenschappen</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.footwearType}
                                    >
                                        <FormLabel>Type passchoen links</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.footwearType}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'footwearType', side: 'left', value }))}
                                            options={orderType === 'VLOS' ? ['VLOS'] : data.footwearType}
                                            disabled={orderType === 'VLOS'}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.footwearType}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.footwearType}
                                    >
                                        <FormLabel>Type passchoen rechts</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.footwearType}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'footwearType', side: 'right', value }))}
                                            options={orderType === 'VLOS' ? ['VLOS'] : data.footwearType}
                                            className={classes.autoComplete}
                                            disabled={orderType === 'VLOS'}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.footwearType}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.lining}
                                    >
                                        <FormLabel>Voering links</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.lining}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'lining', side: 'left', value }))}
                                            options={data.lining}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.lining}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.liningMaterial}
                                    >
                                        <FormLabel>Voering materiaal links</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.liningMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'liningMaterial', side: 'left', value }))}
                                            options={data.liningMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.liningMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.lining}
                                    >
                                        <FormLabel>Voering rechts</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.lining}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'lining', side: 'right', value }))}
                                            options={data.lining}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.lining}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.liningMaterial}
                                    >
                                        <FormLabel>Voering materiaal rechts</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.liningMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'liningMaterial', side: 'right', value }))}
                                            options={data.liningMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.liningMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Lange tijd lopen"
                                            control={
                                                <Checkbox
                                                    checked={sampleShoe.longDistanceWalking}
                                                    onChange={event => dispatch(sampleShoeData({ value: event.target.checked, key: 'longDistanceWalking' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Tongklittenband"
                                            control={
                                                <Checkbox
                                                    checked={sampleShoe.tongueVelcro}
                                                    onChange={event => dispatch(sampleShoeData({ value: event.target.checked, key: 'tongueVelcro' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        error={error.insideSole}
                                    >
                                        <FormLabel>Binnenzool</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.insideSole}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'insideSole', value }))}
                                            options={data.insideSole}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.insideSole}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Tongpolster links"
                                            control={
                                                <Checkbox
                                                    checked={sampleShoe.left.tonguePad}
                                                    onChange={event => dispatch(sampleShoeData({ value: event.target.checked, side: 'left', key: 'tonguePad' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.tonguePadMaterial}
                                    >
                                        <FormLabel>Tongpolster materiaal links</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.tonguePadMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'tonguePadMaterial', side: 'left', value }))}
                                            options={data.tonguePadMaterial}
                                            className={classes.autoComplete}
                                            disabled={!sampleShoe.left.tonguePad}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.tonguePadMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Tongpolster rechts"
                                            control={
                                                <Checkbox
                                                    checked={sampleShoe.right.tonguePad}
                                                    onChange={event => dispatch(sampleShoeData({ value: event.target.checked, side: 'right', key: 'tonguePad' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.tonguePadMaterial}
                                    >
                                        <FormLabel>Tongpolster materiaal rechts</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.tonguePadMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'tonguePadMaterial', side: 'right', value }))}
                                            options={data.tonguePadMaterial}
                                            disabled={!sampleShoe.right.tonguePad}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.tonguePadMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.tongueReinforcement}
                                    >
                                        <FormLabel>Tongversteviging links</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.tongueReinforcement}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'tongueReinforcement', side: 'left', value }))}
                                            options={data.tongueReinforcement}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.tongueReinforcement}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.tongueReinforcement}
                                    >
                                        <FormLabel>Tongversteviging rechts</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.tongueReinforcement}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'tongueReinforcement', side: 'right', value }))}
                                            options={data.tongueReinforcement}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.tongueReinforcement}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.anklePadding}
                                    >
                                        <FormLabel>Enkelpolstering links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.anklePadding}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'anklePadding', side: 'left', value }))}
                                            options={data.anklePadding}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.anklePadding}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.anklePadding}
                                    >
                                        <FormLabel>Enkelpolstering rechts*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.anklePadding}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'anklePadding', side: 'right', value }))}
                                            options={data.anklePadding}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.anklePadding}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.anklePaddingMaterial}
                                    >
                                        <FormLabel>Enkelpolstering materiaal*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.anklePaddingMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'anklePaddingMaterial', value }))}
                                            options={data.anklePaddingMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.anklePaddingMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.contrefort}
                                    >
                                        <FormLabel>Contrefort links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.contrefort}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'contrefort', side: 'left', value }))}
                                            options={data.contrefort}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.contrefort}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.contrefortMaterial}
                                    >
                                        <FormLabel>Contrefort materiaal links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.contrefortMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'contrefortMaterial', side: 'left', value }))}
                                            options={data.contrefortMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.contrefortMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.contrefort}
                                    >
                                        <FormLabel>Contrefort rechts*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.contrefort}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'contrefort', side: 'right', value }))}
                                            options={data.contrefort}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.contrefort}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.contrefortMaterial}
                                    >
                                        <FormLabel>Contrefort materiaal rechts*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.contrefortMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'contrefortMaterial', side: 'right', value }))}
                                            options={data.contrefortMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.contrefortMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.schoring}
                                    >
                                        <FormLabel>Schoring links</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.schoring}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'schoring', side: 'left', value }))}
                                            options={data.schoring}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.schoring}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.schoring}
                                    >
                                        <FormLabel>Schoring rechts</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.schoring}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'schoring', side: 'right', value }))}
                                            options={data.schoring}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.schoring}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.entryPoint}
                                    >
                                        <FormLabel>Inschotpunt links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.entryPoint}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'entryPoint', side: 'left', value }))}
                                            options={data.entryPoint}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.entryPoint}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.entryPoint}
                                    >
                                        <FormLabel>Inschotpunt rechts*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.entryPoint}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'entryPoint', side: 'right', value }))}
                                            options={data.entryPoint}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.entryPoint}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.upperHeight}
                                    >
                                        <FormLabel>Schachthoogte links (mm)*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder={data.upperHeight.min + ' - ' + data.upperHeight.max}
                                            value={sampleShoe.left.upperHeight}
                                            error={error.left.upperHeight}
                                            onChange={event => dispatch(sampleShoeData({ key: 'upperHeight', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.upperHeight}
                                    >
                                        <FormLabel>Schachthoogte rechts (mm)*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder={data.upperHeight.min + ' - ' + data.upperHeight.max}
                                            value={sampleShoe.right.upperHeight}
                                            error={error.right.upperHeight}
                                            onChange={event => dispatch(sampleShoeData({ key: 'upperHeight', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.nose}
                                    >
                                        <FormLabel>Neus links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.nose}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'nose', side: 'left', value }))}
                                            options={data.nose}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.nose}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.noseMaterial}
                                    >
                                        <FormLabel>Neus materiaal links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.noseMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'noseMaterial', side: 'left', value }))}
                                            options={data.noseMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.noseMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.nose}
                                    >
                                        <FormLabel>Neus rechts*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.nose}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'nose', side: 'right', value }))}
                                            options={data.nose}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.nose}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.noseMaterial}
                                    >
                                        <FormLabel>Neus materiaal rechts*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.noseMaterial}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'noseMaterial', side: 'right', value }))}
                                            options={data.noseMaterial}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.noseMaterial}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.closingMechanism}
                                    >
                                        <FormLabel>Sluiting schoen</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.closingMechanism}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'closingMechanism', value }))}
                                            options={data.closingMechanism}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.closingMechanism}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} disableGutters expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>Zool eigenschappen</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={4}>

                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.heelHeight}
                                    >
                                        <FormLabel>Hakhoogte links (mm)*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.left.heelHeight}
                                            error={error.left.heelHeight}
                                            onChange={event => dispatch(sampleShoeData({ key: 'heelHeight', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.heelHeight}
                                    >
                                        <FormLabel>Hakhoogte rechts (mm)*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.right.heelHeight}
                                            error={error.right.heelHeight}
                                            onChange={event => dispatch(sampleShoeData({ key: 'heelHeight', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.soleSpace}
                                    >
                                        <FormLabel>Ruimtezool links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.soleSpace}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'soleSpace', side: 'left', value }))}
                                            options={data.soleSpace}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.soleSpace}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.soleSpace}
                                    >
                                        <FormLabel>Ruimtezool rechts</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.soleSpace}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'soleSpace', side: 'right', value }))}
                                            options={data.soleSpace}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.soleSpace}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.soleStiffening}
                                    >
                                        <FormLabel>Zoolverstijving links*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.left.soleStiffening}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'soleStiffening', side: 'left', value }))}
                                            options={data.soleStiffening}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.left.soleStiffening}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.soleStiffening}
                                    >
                                        <FormLabel>Zoolverstijving rechts*</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.right.soleStiffening}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'soleStiffening', side: 'right', value }))}
                                            options={data.soleStiffening}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.right.soleStiffening}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.heelBuffer}
                                    >
                                        <FormLabel>Bufferhak</FormLabel>
                                        <Autocomplete
                                            value={sampleShoe.heelBuffer}
                                            onChange={(event, value) => dispatch(sampleShoeData({ key: 'heelBuffer', value }))}
                                            options={data.heelBuffer}
                                            className={classes.autoComplete}
                                            renderInput={(params) =>
                                                <TextField
                                                    error={error.heelBuffer}
                                                    {...params} />
                                            } />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.heelRounding}
                                    >
                                        <FormLabel>Hakafronding links (mm)*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.left.heelRounding}
                                            error={error.left.heelRounding}
                                            onChange={event => dispatch(sampleShoeData({ key: 'heelRounding', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.heelRounding}
                                    >
                                        <FormLabel>Hakafronding rechts (mm)*</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.right.heelRounding}
                                            error={error.right.heelRounding}
                                            onChange={event => dispatch(sampleShoeData({ key: 'heelRounding', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.soleElevationHeel}
                                    >
                                        <FormLabel>Hakzool verhoging hiel links</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.left.soleElevationHeel}
                                            error={error.left.soleElevationHeel}
                                            onChange={event => dispatch(sampleShoeData({ key: 'soleElevationHeel', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.soleElevationHeel}
                                    >
                                        <FormLabel>Hakzool verhoging hiel rechts</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.right.soleElevationHeel}
                                            error={error.right.soleElevationHeel}
                                            onChange={event => dispatch(sampleShoeData({ key: 'soleElevationHeel', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.soleElevationBall}
                                    >
                                        <FormLabel>Hakzool verhoging bal links</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.left.soleElevationBall}
                                            error={error.left.soleElevationBall}
                                            onChange={event => dispatch(sampleShoeData({ key: 'soleElevationBall', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.soleElevationBall}
                                    >
                                        <FormLabel>Hakzool verhoging bal rechts</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.right.soleElevationBall}
                                            error={error.right.soleElevationBall}
                                            onChange={event => dispatch(sampleShoeData({ key: 'soleElevationBall', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.soleElevationToe}
                                    >
                                        <FormLabel>Hakzool verhoging teen links</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.left.soleElevationToe}
                                            error={error.left.soleElevationToe}
                                            onChange={event => dispatch(sampleShoeData({ key: 'soleElevationToe', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.soleElevationToe}
                                    >
                                        <FormLabel>Hakzool verhoging teen rechts</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.right.soleElevationToe}
                                            error={error.right.soleElevationToe}
                                            onChange={event => dispatch(sampleShoeData({ key: 'soleElevationToe', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.left.addedLength}
                                    >
                                        <FormLabel>Neusverlenging links (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.left.addedLength}
                                            error={error.left.addedLength}
                                            onChange={event => dispatch(sampleShoeData({ key: 'addedLength', side: 'left', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                        error={error.right.addedLength}
                                    >
                                        <FormLabel>Neusverlenging rechts (mm)</FormLabel>
                                        <Box className={classes.autoComplete}></Box>
                                        <TextField
                                            type='number'
                                            placeholder='0'
                                            value={sampleShoe.right.addedLength}
                                            error={error.right.addedLength}
                                            onChange={event => dispatch(sampleShoeData({ key: 'addedLength', side: 'right', value: event.target.value }))}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <FormControlLabel
                                            label="Werktekening aanwezig"
                                            control={
                                                <Checkbox
                                                    checked={sampleShoe.technicalDrawing}
                                                    onChange={event => dispatch(sampleShoeData({ value: event.target.checked, key: 'technicalDrawing' }))} />}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking proef/ voeringschoen</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={sampleShoe.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(sampleShoeData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(sampleShoe)
