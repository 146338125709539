export const initialState = {
    sampleShoeData: {
        insideSole: '',
        externalLining: false,
        anklePaddingMaterial: '',
        heelBuffer: '',
        closingMechanism: '',
        technicalDrawing: false,
        note: '',
        left: {
            footwearType: '',
            lining: '',
            liningMaterial: '',
            longDistanceWalking: false,
            tongueVelcro: false,
            tonguePad: false,
            tonguePadMaterial: '',
            tongueReinforcement: '',
            anklePadding: '',
            contrefort: '',
            contrefortMaterial: '',
            schoring: '',
            nose: '',
            noseMaterial: '',
            heelHeight: '',
            entryPoint: '',
            upperHeight: '',
            soleSpace: '',
            soleStiffening: '',
            heelRounding: '',
            soleElevationHeel: '',
            soleElevationBall: '',
            soleElevationToe: '',
            addedLength: '',
            transverseSupport: '',
            transverseSupportHeight: '',
            pelot: '',
            pelotHeight: '',
            distanceToHeel: '',
            toeTreshold: '',
            toeTresholdMaterial: '',
            heelCurb: '',
            heelCurbMaterial: '',
            toeCutout: false,
            toes: '',
            front: '',
            heel: '',
            complete: '',
            finishingSoleStiffening: '',
            coverMaterial: ''
        },
        right: {
            footwearType: '',
            lining: '',
            liningMaterial: '',
            longDistanceWalking: false,
            tongueVelcro: false,
            tonguePad: false,
            tonguePadMaterial: '',
            tongueReinforcement: '',
            anklePadding: '',
            contrefort: '',
            contrefortMaterial: '',
            schoring: '',
            nose: '',
            noseMaterial: '',
            heelHeight: '',
            entryPoint: '',
            upperHeight: '',
            soleSpace: '',
            soleStiffening: '',
            heelRounding: '',
            soleElevationHeel: '',
            soleElevationBall: '',
            soleElevationToe: '',
            closingMechanism: '',
            addedLength: '',
            transverseSupport: '',
            transverseSupportHeight: '',
            pelot: '',
            pelotHeight: '',
            distanceToHeel: '',
            toeTreshold: '',
            toeTresholdMaterial: '',
            heelCurb: '',
            heelCurbMaterial: '',
            toeCutout: false,
            toes: '',
            front: '',
            heel: '',
            complete: '',
            finishingSoleStiffening: '',
            coverMaterial: ''
        }
    },
    sampleShoeAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    sampleShoeErrors: {
        message: [],
        externalLining: false,
        anklePaddingMaterial: false,
        heelBuffer: false,
        closingMechanism: false,
        left: {
            footwearType: false,
            lining: false,
            liningMaterial: false,
            longDistanceWalking: false,
            tongueVelcro: false,
            tonguePad: false,
            tonguePadMaterial: false,
            tongueReinforcement: false,
            anklePadding: false,
            contrefort: false,
            contrefortMaterial: false,
            schoring: false,
            nose: false,
            noseMaterial: false,
            heelHeight: false,
            entryPoint: false,
            upperHeight: false,
            soleSpace: false,
            soleStiffening: false,
            heelRounding: false,
            soleElevationHeel: false,
            soleElevationBall: false,
            soleElevationToe: false,
            transverseSupport: false,
            transverseSupportHeight: false,
            pelot: false,
            pelotHeight: false,
            distanceToHeel: false,
            toeTreshold: false,
            toeTresholdMaterial: false,
            heelCurb: false,
            heelCurbMaterial: false,
            toeCutout: false,
            toes: false,
            front: false,
            heel: false,
            complete: false,
            finishingSoleStiffening: false,
            coverMaterial: false
        },
        right: {
            footwearType: false,
            lining: false,
            liningMaterial: false,
            longDistanceWalking: false,
            tongueVelcro: false,
            tonguePad: false,
            tonguePadMaterial: false,
            tongueReinforcement: false,
            anklePadding: false,
            contrefort: false,
            contrefortMaterial: false,
            schoring: false,
            nose: false,
            noseMaterial: false,
            heelHeight: false,
            entryPoint: false,
            upperHeight: false,
            soleSpace: false,
            soleStiffening: false,
            heelRounding: false,
            soleElevationHeel: false,
            soleElevationBall: false,
            soleElevationToe: false,
            transverseSupport: false,
            transverseSupportHeight: false,
            pelot: false,
            pelotHeight: false,
            distanceToHeel: false,
            toeTreshold: false,
            toeTresholdMaterial: false,
            heelCurb: false,
            heelCurbMaterial: false,
            toeCutout: false,
            toes: false,
            front: false,
            heel: false,
            complete: false,
            finishingSoleStiffening: false,
            coverMaterial: false
        }
    }
}
