export const initialState = {
    repairData: {
        note: '',
        left: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            rocker: '',
            heel: '',
            ball: '',
            toe: '',
            correction: '',
            correctionHeight: '',
            lateral: '',
            medial: '',
            soleStiffening: '',
            stretch: '',
            solePad: '',
            closureAdjust: '',
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: '',
            tongueStitching: false,
            heelLining: false,
            repairStitching: false
        },
        right: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            rocker: '',
            heel: '',
            ball: '',
            toe: '',
            correction: '',
            correctionHeight: '',
            lateral: '',
            medial: '',
            soleStiffening: '',
            stretch: '',
            solePad: '',
            closureAdjust: '',
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: '',
            tongueStitching: false,
            heelLining: false,
            repairStitching: false
        }
    },
    repairAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    repairErrors: {
        message: [],
        left: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            rocker: false,
            heel: false,
            ball: false,
            toe: false,
            correction: false,
            correctionHeight: false,
            lateral: false,
            medial: false,
            soleStiffening: false,
            stretch: false,
            solePad: false,
            closureAdjust: false,
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: false,
            tongueStitching: false,
            heelLining: false,
            repairStitching: false
        },
        right: {
            rubberSole: false,
            leatherSole: false,
            heels: false,
            generalSole: false,
            heelBuffer: false,
            heelRounding: false,
            rocker: false,
            heel: false,
            ball: false,
            toe: false,
            correction: false,
            correctionHeight: false,
            lateral: false,
            medial: false,
            soleStiffening: false,
            stretch: false,
            solePad: false,
            closureAdjust: false,
            tongueVelcro: false,
            replaceAdhesive: false,
            extendVelcro: false,
            shortenVelcro: false,
            openPosition: false,
            tongueStitching: false,
            heelLining: false,
            repairStitching: false
        }
    }
}
