import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import clsx from 'clsx'
import useStyles from './style'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../lib/api'
import { patientData, setError, setOrderType } from '../../store/reducers/general'
import { useDispatch } from 'react-redux'
import pck from '../../../package.json'

import { withStyles } from '@mui/styles'

const OSAWit = require('../../assets/Icon - OSA schoen wit.svg')
const OSBWit = require('../../assets/Icon - OSB schoen wit.svg')
const OVACwit = require('../../assets/Icon - OVAC schoen wit.svg')
const Repairwit = require('../../assets/Icon - Reparatie wit.svg')
const insoleWit = require('../../assets/zoolLogo-wit.svg')

const HomePage = ({ classes }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [queryParameters] = useSearchParams()
    /**
     * get the authenticated podiatrist's name and email
     * (activated only in production)
     */
    useEffect(() => {
        // save the patient number when given.
        const patient = queryParameters.get('patient')
        if (patient !== null) dispatch(patientData({ key: 'number', value: patient }))

        const fetchTherapists = () => {
            api.therapists((rsp) => {
                dispatch(patientData({
                    key: 'therapist',
                    value: {
                        name: rsp.name,
                        email: rsp.email
                    }
                }),
                api.setSharepointInformation(rsp.microsoftToken, queryParameters)
                )
            }, (error) => { dispatch(setError(error)) })
        }

        fetchTherapists()
    }, [])

    /**
     * Continue to order page.
     */
    function toOSA () {
        navigate('/osa')
    }

    /**
     * Continue to order page.
     */
    function toOSB () {
        navigate('/osb')
        dispatch(setOrderType('OSB'))
    }

    /**
     * Continue to order page.
     */
    function toOVAC () {
        navigate('/ovac')
        dispatch(setOrderType('OVAC'))
    }
    /**
     * Continue to order page.
     */
    function toRepair () {
        navigate('/repair')
        dispatch(setOrderType('Reparatie'))
    }

    /**
     * Continue to order page.
     */
    function toInsole () {
        navigate('/insole')
        dispatch(setOrderType('Steunzool'))
    }

    return (
        <Grid justifyContent="center" alignItems="center" container spacing={5}>
            <Grid item xs={4} onClick={toOSA}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.tertiary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={OSAWit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>OSA</Typography>
                </Grid>
            </Grid>
            <Grid item xs={4} onClick={toOSB}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.secondary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={OSBWit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>OSB</Typography>
                </Grid>
            </Grid>
            <Grid item xs={4} onClick={toOVAC}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.primary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={OVACwit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>OVAC</Typography>
                </Grid>
            </Grid>
            <Grid item xs={4} onClick={toRepair}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.tertiary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={Repairwit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>Reparatie</Typography>
                </Grid>
            </Grid>
            <Grid item xs={4} onClick={toInsole}>
                <Grid
                    container
                    className={clsx([classes.paper, classes.primary])}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <img className={classes.svgPaper} src={insoleWit} alt="Wender Logo" />
                    <Typography variant="h6" className={classes.paperText}>Steunzool</Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.version} justifyContent="center">
                <Typography className={classes.versionText} variant="caption" align='center'>v{pck.version}</Typography>
            </Grid>
        </Grid>
    )
}

export default withStyles(useStyles)(HomePage)
