import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    FormControl,
    // FormGroup,
    FormLabel,
    Grid,
    Typography,
    Autocomplete,
    TextField,
    styled,
    FormControlLabel,
    Checkbox,
    Box
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useSelector, useDispatch } from 'react-redux'
import { repairAccordion, repairData } from '../../store/reducers/repair'

import useStyles from './style'
import { withStyles } from '@mui/styles'
import { deepCopy } from '../../lib/util'

import dataProvider from '../../lib/dataProvider'

const data = dataProvider.repair

const repair = ({ classes }) => {
    // state management
    const dispatch = useDispatch()
    const repair = useSelector((state) => state.repair.repairData)
    const error = useSelector((state) => state.repair.repairErrors)
    const tab = useSelector((state) => state.repair.repairAccordion)
    const [expanded, setExpanded] = React.useState(['panel1'])
    // const other = useSelector((state) => state.other)

    /**
     * Toggle the accordion.
     */
    function toggleAccordion () {
        dispatch(repairAccordion({ key: 'accordion', value: !tab.accordion }))
    }

    /**
     * Handle tab change
     * @param {*} event - event
     * @param {*} newValue - selected tab
     */
    function handleChange (accordionValue) {
        const newExpanded = deepCopy(expanded)

        if (newExpanded.includes(accordionValue)) {
            newExpanded.splice(newExpanded.indexOf(accordionValue), 1)
        } else {
            newExpanded.push(accordionValue)
        }

        setExpanded(newExpanded)
    }

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'rgba(0, 0, 0, .02)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)'
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1)
        }
    }))

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    // function setCheckedTonguePad (event, side) {
    //     // dispatch(sampleShoeData({ key: 'tonguePad', side, value: event.target.checked }))
    // };

    return (
        <Accordion defaultExpanded={true} expanded={tab.accordion}>
            <MuiAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={toggleAccordion}
                className={!tab.accordion
                    ? tab.hasBeenOpened
                        ? tab.validate
                            ? classes.accordionSuccess
                            : classes.accordionError
                        : null
                    : null}
            >
                <Typography
                    className={!tab.accordion
                        ? tab.hasBeenOpened
                            ? tab.validate
                                ? classes.titleSuccess
                                : classes.titleError
                            : classes.title
                        : classes.title}>Reparatie/ aanpassing</Typography>
            </MuiAccordionSummary>
            <AccordionDetails>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel1')} onChange={(event) => handleChange('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Schacht</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.stretch}
                                >
                                    <FormLabel>Oprekken links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.stretch}
                                        onChange={(event, value) => dispatch(repairData({ key: 'stretch', side: 'left', value }))}
                                        options={data.stretch}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.stretch}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.stretch}
                                >
                                    <FormLabel>Oprekken rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.stretch}
                                        onChange={(event, value) => dispatch(repairData({ key: 'stretch', side: 'right', value }))}
                                        options={data.stretch}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.stretch}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.solePad}
                                >
                                    <FormLabel>Tongpolster links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.solePad}
                                        onChange={(event, value) => dispatch(repairData({ key: 'solePad', side: 'left', value }))}
                                        options={data.solePad}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.solePad}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.solePad}
                                >
                                    <FormLabel>Tongpolster rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.solePad}
                                        onChange={(event, value) => dispatch(repairData({ key: 'solePad', side: 'right', value }))}
                                        options={data.solePad}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.solePad}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.closureAdjust}
                                >
                                    <FormLabel>Sluiting aanpassen links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.closureAdjust}
                                        onChange={(event, value) => dispatch(repairData({ key: 'closureAdjust', side: 'left', value }))}
                                        options={data.closureAdjust}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.closureAdjust}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.closureAdjust}
                                >
                                    <FormLabel>Sluiting aanpassen rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.closureAdjust}
                                        onChange={(event, value) => dispatch(repairData({ key: 'closureAdjust', side: 'right', value }))}
                                        options={data.closureAdjust}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.closureAdjust}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband aan tong links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.tongueVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'tongueVelcro' }))} />}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband aan tong rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.tongueVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'tongueVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Kleefband vervangen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.replaceAdhesive}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'replaceAdhesive' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Kleefband vervangen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.replaceAdhesive}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'replaceAdhesive' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Tong vaststikken links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.tongueStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'tongueStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Tong vaststikken rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.tongueStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'tongueStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hielvoering links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heelLining}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heelLining' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hielvoering rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heelLining}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heelLining' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Stiksel herstellen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.repairStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'repairStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Stiksel herstellen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.repairStitching}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'repairStitching' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verlengen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.extendVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'extendVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verlengen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.extendVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'extendVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verkorten links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.shortenVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'shortenVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Klittenband verkorten rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.shortenVelcro}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'shortenVelcro' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.openPosition}
                                >
                                    <FormLabel>Openstand links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        placeholder='0'
                                        value={repair.left.openPosition}
                                        error={error.left.openPosition}
                                        onChange={event => dispatch(repairData({ key: 'openPosition', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.openPosition}
                                >
                                    <FormLabel>Openstand rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        placeholder='0'
                                        value={repair.right.openPosition}
                                        error={error.right.openPosition}
                                        onChange={event => dispatch(repairData({ key: 'openPosition', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel2')} onChange={(event) => handleChange('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Onderwerk</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool rubber links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.rubberSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'rubberSole' }))} />}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool rubber rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.rubberSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'rubberSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool leer links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.leatherSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'leatherSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool leer rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.leatherSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'leatherSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hak links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heels}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heels' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hak rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heels}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heels' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool algemeen links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.generalSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'generalSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Zool algemeen rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.generalSole}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'generalSole' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Bufferhak links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heelBuffer}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heelBuffer' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Bufferhak rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heelBuffer}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heelBuffer' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hakafronding links"
                                        control={
                                            <Checkbox
                                                checked={repair.left.heelRounding}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'left', key: 'heelRounding' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl
                                    fullWidth
                                >
                                    <FormControlLabel
                                        label="Hakafronding rechts"
                                        control={
                                            <Checkbox
                                                checked={repair.right.heelRounding}
                                                onChange={event => dispatch(repairData({ value: event.target.checked, side: 'right', key: 'heelRounding' }))} />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.rocker}
                                >
                                    <FormLabel>Afwikkelbalk links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.rocker}
                                        onChange={(event, value) => dispatch(repairData({ key: 'rocker', side: 'left', value }))}
                                        options={data.rocker}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.rocker}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.rocker}
                                >
                                    <FormLabel>Afwikkelbalk rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.rocker}
                                        onChange={(event, value) => dispatch(repairData({ key: 'rocker', side: 'right', value }))}
                                        options={data.rocker}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.rocker}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heel}
                                >
                                    <FormLabel>Hak links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.heel}
                                        error={error.left.heel}
                                        onChange={event => dispatch(repairData({ key: 'heel', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.heel}
                                >
                                    <FormLabel>Hak rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.heel}
                                        error={error.right.heel}
                                        onChange={event => dispatch(repairData({ key: 'heel', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.ball}
                                >
                                    <FormLabel>Bal links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.ball}
                                        error={error.left.ball}
                                        onChange={event => dispatch(repairData({ key: 'ball', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.ball}
                                >
                                    <FormLabel>Bal rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.ball}
                                        error={error.right.ball}
                                        onChange={event => dispatch(repairData({ key: 'ball', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.toe}
                                >
                                    <FormLabel>Teen links (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.toe}
                                        error={error.left.toe}
                                        onChange={event => dispatch(repairData({ key: 'toe', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.toe}
                                >
                                    <FormLabel>Teen rechts (mm)</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.toe}
                                        error={error.right.toe}
                                        onChange={event => dispatch(repairData({ key: 'toe', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.correction}
                                >
                                    <FormLabel>Correctie links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.correction}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correction', side: 'left', value }))}
                                        options={data.correction}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.correction}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.left.correctionHeight}
                                >
                                    <FormLabel>Hoogte correctie links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.correctionHeight}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correctionHeight', side: 'left', value }))}
                                        options={data.correctionHeight}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.correctionHeight}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.correction}
                                >
                                    <FormLabel>Correctie rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.correction}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correction', side: 'right', value }))}
                                        options={data.correction}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.correction}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl
                                    fullWidth
                                    error={error.right.correctionHeight}
                                >
                                    <FormLabel>Hoogte correctie rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.correctionHeight}
                                        onChange={(event, value) => dispatch(repairData({ key: 'correctionHeight', side: 'right', value }))}
                                        options={data.correctionHeight}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.correctionHeight}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel3')} onChange={(event) => handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                        <Typography>Hakschoring</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.lateral}
                                >
                                    <FormLabel>Hakschoring lateraal links</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.lateral}
                                        error={error.left.lateral}
                                        onChange={event => dispatch(repairData({ key: 'lateral', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.lateral}
                                >
                                    <FormLabel>Hakschoring lateraal rechts</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.lateral}
                                        error={error.right.lateral}
                                        onChange={event => dispatch(repairData({ key: 'lateral', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.medial}
                                >
                                    <FormLabel>Hakschoring mediaal links</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.left.medial}
                                        error={error.left.medial}
                                        onChange={event => dispatch(repairData({ key: 'medial', side: 'left', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.medial}
                                >
                                    <FormLabel>Hakschoring mediaal rechts</FormLabel>
                                    <Box className={classes.autoComplete}></Box>
                                    <TextField
                                        type='number'
                                        value={repair.right.medial}
                                        error={error.right.medial}
                                        onChange={event => dispatch(repairData({ key: 'medial', side: 'right', value: event.target.value }))}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion elevation={0} disableGutters expanded={expanded.includes('panel4')} onChange={(event) => handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                        <Typography>Zoolverstijving</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.left.soleStiffening}
                                >
                                    <FormLabel>Zoolverstijving links</FormLabel>
                                    <Autocomplete
                                        value={repair.left.soleStiffening}
                                        onChange={(event, value) => dispatch(repairData({ key: 'soleStiffening', side: 'left', value }))}
                                        options={data.soleStiffening}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.left.soleStiffening}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    fullWidth
                                    error={error.right.soleStiffening}
                                >
                                    <FormLabel>Zoolverstijving rechts</FormLabel>
                                    <Autocomplete
                                        value={repair.right.soleStiffening}
                                        onChange={(event, value) => dispatch(repairData({ key: 'soleStiffening', side: 'right', value }))}
                                        options={data.soleStiffening}
                                        className={classes.autoComplete}
                                        renderInput={(params) =>
                                            <TextField
                                                error={error.right.soleStiffening}
                                                {...params} />
                                        } />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Grid container spacing={4}>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                        >
                            <FormLabel>Opmerking reparatie</FormLabel>
                            <Box className={classes.autoComplete}></Box>
                            <TextField
                                type='text'
                                value={repair.note}
                                multiline
                                rows={3}
                                onChange={event => dispatch(repairData({ key: 'note', value: event.target.value }))} />
                        </FormControl>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default withStyles(useStyles)(repair)
