import { configureStore } from '@reduxjs/toolkit'
import generalSlice from './reducers/general'
import other from './reducers/other'
import osbSlice from './reducers/osb'
import osbOutsoleSlice from './reducers/osbOutsole'
import insoleSlice from './reducers/insole'
import sampleShoeSlice from './reducers/sampleShoe'
import osaPatternSlice from './reducers/osaPattern'
import osaSupplementSlice from './reducers/osaSupplement'
import repairSlice from './reducers/repair'
import osaLowerSlice from './reducers/osaLower'
import osaFinishSlice from './reducers/osaFinish'

const store = configureStore({
    reducer: {
        general: generalSlice,
        osbShoe: osbSlice,
        osbOutsole: osbOutsoleSlice,
        insole: insoleSlice,
        sampleShoe: sampleShoeSlice,
        osaPattern: osaPatternSlice,
        osaSupplement: osaSupplementSlice,
        osaLower: osaLowerSlice,
        osaFinish: osaFinishSlice,
        repair: repairSlice,
        other
    },
    // removes a 'non-serializable value was detected in the state' error when selecting a date
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export default store
