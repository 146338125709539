import React, { useState } from 'react'
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Table,
    TableRow,
    TableCell,
    TableBody,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Snackbar,
    Alert as MuiAlert
} from '@mui/material'
import useStyles from './style'
import moment from 'moment'
import api from '../../lib/api'
import { withStyles } from '@mui/styles'
import { useSelector } from 'react-redux'

const Overview = ({ classes }) => {
    const patient = useSelector((state) => state.general)
    const orderType = useSelector((state) => state.general.orderType)
    const sampleShoe = useSelector((state) => state.sampleShoe)
    const repair = useSelector((state) => state.repair)
    const osaPattern = useSelector((state) => state.osaPattern)
    const osaLower = useSelector((state) => state.osaLower)
    const osaFinish = useSelector((state) => state.osaFinish)
    const osaSupplement = useSelector((state) => state.osaSupplement)
    const insole = useSelector((state) => state.insole)
    const osb = useSelector((state) => state.osbShoe)
    const osbOutsole = useSelector((state) => state.osbOutsole)

    const [sendToPodoIT, setSendToPodoIT] = useState(true)
    const [sendToSharepoint, setSendToSharepoint] = useState(false)

    const [successPodoIT, setDisplayPodoIT] = useState(false)

    const Alert = React.forwardRef(function Alert (props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })
    /**
     * Generate a filename.
     *
     * @return {String} - the generatedd filename
     */
    function generateFileName (extension) {
        const today = moment().format('DD-MM-YYYY')
        const hrs = moment().format('hh:mm:ss')
        return `${today}_${hrs}_${patient.patientData.lastName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(' ', '_')}_${patient.patientData.number.replace(' ', '')}.${extension}`
    }

    /**
     * Return to homepage
     */
    function returnToStart () {
        window.location.replace(api.accountLinkUrl)
    }

    /**
     * callback function called when the
     * snackbar should close
     */
    function onSnackbarClose (key) {
        switch (key) {
        case 'successPodoIT':
            setDisplayPodoIT(false)
            break
        }
    }

    /**
     *
     * @param {*} error
     */
    function onError (error) {
        console.error(error)
    }

    /**
     * place podo-it order
     */
    function toPodoIT () {
        if (!sendToPodoIT) return
        const today = moment().format('DD-MM-YYYY')
        const reqFields = requiredFields()
        reqFields.orderType = orderType
        reqFields.fileName = `${patient.patientData.number.replace(' ', '')}_${orderType}_${today}.json`
        reqFields.sendDate = moment().toISOString()

        api.placeOrderBootform(reqFields, onSuccessJson, onError)
        api.retrievePDF(reqFields, sendToSharepoint, onSuccess, orderType, patient)

        setSendToPodoIT(false)
    }

    /**
     *
     */
    function requiredFields () {
        let state = {}

        switch (orderType) {
        case 'Proefschoen':
            state = { ...patient, ...sampleShoe }
            break
        case 'VLOS':
            state = { ...patient, ...osaSupplement, ...sampleShoe }
            break
        case 'OSA':
            state = { ...patient, ...osaPattern, ...osaLower, ...osaFinish }
            break
        case 'Passchoen':
            state = { ...patient, ...osaSupplement }
            break
        case 'Steunzool':
            state = { ...patient, ...insole }
            break
        case 'OSB':
            state = { ...patient, ...osb, ...insole, ...osbOutsole }
            break
        case 'OVAC':
            state = { ...patient, ...osbOutsole, ...insole }
            break
        case 'Reparatie':
            state = { ...patient, ...repair }
            break
        }
        return state
    }

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    function setChecked (event) {
        setSendToPodoIT(event.target.checked)
    };

    /**
     *
     */
    function onSuccess () {
        setDisplayPodoIT(true)
    }

    /**
     *
     */
    function onSuccessJson (response) {
        // setDisplayPodoIT(true)
        console.log(response)
    }

    /**
     * Set send to podoIt true or false.
     *
     * @param {Boolean} event - bool
     */
    function setCheckedSharepoint (event) {
        setSendToSharepoint(event.target.checked)
    };

    /**
     * Display buttons to continue with
     *
     * @param {Object} hasDownloadJson - boolean
     * @return {JSX} - button bar with objects.
     */
    function ButtonBar ({ hasDownloadJson }) {
        return <div>
            <Grid container className={classes.buttonBar} justifyContent="space-between">
                <Grid xs={3} container direction="column">
                    <Button
                        variant="contained"
                        onClick={returnToStart}
                        color='success'
                    >
                        Nieuwe klant
                    </Button>
                </Grid>
                <Grid xs={3}></Grid>
                <Grid xs={3} container direction="column">
                    <Button
                        variant="contained"
                        onClick={toPodoIT}
                        color='primary'
                    >
                        Verstuur
                    </Button>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox disabled checked={sendToPodoIT} onChange={setChecked} />} label="Opslaan in bestandsbeheer"/>
                        <FormControlLabel control={<Checkbox checked={sendToSharepoint} onChange={setCheckedSharepoint} />} label={'Stuur naar werkplaats'} />
                    </FormGroup>
                </Grid>
            </Grid>
        </div>
    }
    return (
        <div>
            <ButtonBar />
            <Card variant="outlined">
                <CardContent>
                    <Typography className={classes.title} variant="h5" component="h5">
                        Algemene Patientgegevens
                    </Typography>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>Aanleverdatum werkplaats</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? moment(patient.patientData.deliveryDate).format('DD/MM/YYYY') : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Datum gereed</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? moment(patient.patientData.readyDate).format('DD/MM/YYYY') : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Afspraak datum</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? moment(patient.patientData.appointmentDate).format('DD/MM/YYYY') : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Ingezonden freesfile</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{generateFileName('pdf')}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Patiëntnaam</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? patient.patientData.lastName : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Patiëntnummer</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? patient.patientData.number : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>

                                    <Typography>Geboortedatum</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient ? moment(patient.patientData.birthdate).format('DD/MM/YYYY') : ''}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Geslacht</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{patient.patientData.gender === 'male' ? 'Man' : 'Vrouw'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Aanmeetlocatie</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {patient.patientData.fittingLocation
                                            ? patient.patientData.fittingLocation.title
                                            : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Afleverlocatie</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>
                                        {patient.patientData.receiveLocation
                                            ? patient.patientData.receiveLocation.title
                                            : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            <Card variant="outlined"></Card>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={successPodoIT} autoHideDuration={6000} onClose={() => onSnackbarClose('successPodoIT')}>
                <Alert onClose={() => onSnackbarClose('successPodoIT')} severity="success" sx={{ width: '100%' }}>
                    Successvol opgeslagen in bestandsbeheer {sendToSharepoint && 'en verstuurd naar werkplaats'}
                </Alert>
            </Snackbar>

            <div className={classes.bottomPageSpacer}>
                <ButtonBar/>
            </div>
        </div>
    )
}

export default withStyles(useStyles)(Overview)
