export const stock = [
    'Besteld bij KS',
    'Besteld bij externe',
    'Uit voorraad'
]

export const shoeType = [
    'Moliere',
    'Velcro'
]

export const sizeLeft = { min: 0 }
export const sizeRight = { min: 0 }
export const widthLeft = { min: 0 }
export const widthRight = { min: 0 }
