import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateNumber, validateLastName, validateOrderNumber, validateBirthdate, validateGender, validateFittingLocation, validateAllFields } from './validation.js'
import moment from 'moment'

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        patientData (state, action) {
            const key = action.payload.key
            const value = action.payload.value === null ? '' : action.payload.value
            switch (action.payload.key) {
            case 'therapist':
                state.patientData.therapist = action.payload.value
                break
            case 'number':
                state.patientData.number = action.payload.value
                validateNumber(state, action.payload.value)
                break
            case 'orderNumber':
                state.patientData.orderNumber = action.payload.value
                validateOrderNumber(state, action.payload.value)
                break
            case 'postponeOrder':
                state.patientData.postponeOrder = action.payload.value
                break
            case 'birthdate':
                state.patientData.birthdate = action.payload.value
                validateBirthdate(state, action.payload.value)
                break
            case 'lastName':
                state.patientData.lastName = action.payload.value
                validateLastName(state, action.payload.value)
                break
            case 'gender':
                state.patientData.gender = action.payload.value
                validateGender(state, action.payload.value)
                break
            case 'fittingLocation':
                state.patientData.fittingLocation = action.payload.value
                validateFittingLocation(state, action.payload.value)
                validateAllFields(state)
                break
            case 'receiveLocation':
                state.patientData.receiveLocation = action.payload.value
                validateAllFields(state)
                break
            case 'emergencyOrder':
                state.patientData.emergencyOrder = action.payload.value
                validateAllFields(state)
                break
            case 'id':
                state.patientData.id = action.payload.value
                break
            case 'initials':
                state.patientData.initials = action.payload.value
                break
            case 'readyDate':
                state.patientData.readyDate = action.payload.value
                break
            case 'deliveryDate':
                state.patientData.deliveryDate = action.payload.value
                calculateDates(state, action.payload.value)
                break
            default:
                state.patientData[key] = value
                break
            }
        },
        resetPatientData (state) {
            state.patientData.birthdate = moment()
            state.patientData.initials = ''
            state.patientData.gender = 'male'
            state.patientData.id = ''
            state.patientData.lastName = ''
            state.patientData.initials = ''
        },
        setOrderType (state, action) {
            state.orderType = action.payload
        },
        generalAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                if (!state.generalAccordion.hasBeenOpened && state.generalAccordion.accordion && !action.payload.value) {
                    state.generalAccordion.hasBeenOpened = true
                }
                state.generalAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.generalAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.generalAccordion.hasBeenOpened = true
                break
            }
        },
        setError (state, action) {
            state.generalErrors.message.push(action.payload.message)
        },
        closeGeneral (state) {
            state.generalAccordion.accordion = false
            state.generalAccordion.hasBeenOpened = true
            validateAllFields(state)
        }
    }
})
/**
 *
 * @param {*} state
 * @param {*} value
 */
function calculateDates (state, value) {
    switch (state.orderType) {
    case 'Proefschoen':
        state.patientData.readyDate = moment(state.patientData.deliveryDate).add('2', 'weeks')
        state.patientData.appointmentDate = moment(state.patientData.deliveryDate).add('2', 'weeks')
        break
    case 'OSA':
        state.patientData.readyDate = moment(state.patientData.deliveryDate).add('6', 'weeks')
        state.patientData.appointmentDate = moment(state.patientData.deliveryDate).add('6', 'weeks')
        break
    case 'Passchoen':
        state.patientData.readyDate = moment(state.patientData.deliveryDate).add('2', 'weeks')
        state.patientData.appointmentDate = moment(state.patientData.deliveryDate).add('2', 'weeks')
        break
    case 'Steunzool':
        state.patientData.readyDate = moment(state.patientData.deliveryDate).add('2', 'weeks')
        state.patientData.appointmentDate = moment(state.patientData.deliveryDate).add('2', 'weeks')
        break
    case 'OSB':
        state.patientData.readyDate = moment(state.patientData.deliveryDate)
        break
    case 'OVAC':
        state.patientData.readyDate = moment(state.patientData.deliveryDate)
        break
    case 'VLOS':
        state.patientData.readyDate = moment(state.patientData.deliveryDate)
        break
    case 'Reparatie':
        state.patientData.readyDate = moment(state.patientData.deliveryDate)
        break
    default:
        break
    }
}

export const { patientData, generalAccordion, closeGeneral, setError, setOrderType, resetPatientData } = generalSlice.actions
export default generalSlice.reducer
