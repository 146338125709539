/**
 * validate if fields are not empty.
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateAllFields (state) {
    if (!state.insoleAccordion.hasBeenOpened) return
    state.insoleAccordion.validate = true
    // state.insoleAccordion.validate = checkEmptyFields(state)
}

/**
 * Check if the fields of general are empty.
 *
 * @param {Object} state - redux state object
 * @returns {boolean} true
 */
function checkEmptyFields (state) {
    const left = state.insoleData.left
    const right = state.insoleData.right
    const insole = state.insoleData

    state.insoleErrors.developMethod = equalOrNull(insole.developMethod)
    state.insoleErrors.insoleType = equalOrNull(insole.insoleType)
    state.insoleErrors.stiffness = equalOrNull(insole.stiffness)
    state.insoleErrors.soleSpecification = equalOrNull(insole.soleSpecification)
    state.insoleErrors.soleMaterial = equalOrNull(insole.soleMaterial)
    state.insoleErrors.heelSpur = equalOrNull(insole.heelSpur)
    state.insoleErrors.coverMaterial = equalOrNull(insole.coverMaterial)

    state.insoleErrors.left.correction = equalOrNull(left.correction)
    state.insoleErrors.right.correction = equalOrNull(right.correction)

    state.insoleErrors.left.heelLift = equalOrNull(left.heelLift)
    state.insoleErrors.right.heelLift = equalOrNull(right.heelLift)

    state.insoleErrors.left.transverseSupport = equalOrNull(left.transverseSupport)
    state.insoleErrors.right.transverseSupport = equalOrNull(right.transverseSupport)

    state.insoleErrors.left.pelot = equalOrNull(left.pelot)
    state.insoleErrors.right.pelot = equalOrNull(right.pelot)

    state.insoleErrors.left.toeBarMaterial = equalOrNull(left.toeBarMaterial)
    state.insoleErrors.right.toeBarMaterial = equalOrNull(right.toeBarMaterial)
    state.insoleErrors.left.toeBar = equalOrNull(left.toeBar)
    state.insoleErrors.right.toeBar = equalOrNull(right.toeBar)

    return !equalOrNull(insole.developMethod) &&
    !equalOrNull(insole.insoleType) &&
    !equalOrNull(insole.stiffness) &&
    !equalOrNull(insole.soleSpecification) &&
    !equalOrNull(insole.soleMaterial) &&
    !equalOrNull(insole.heelSpur) &&
    !equalOrNull(insole.coverMaterial) &&

    !equalOrNull(left.correction) &&
    !equalOrNull(right.correction) &&

    !equalOrNull(left.heelLift) &&
    !equalOrNull(right.heelLift) &&

    !equalOrNull(left.transverseSupport) &&
    !equalOrNull(right.transverseSupport) &&

    !equalOrNull(left.pelot) &&
    !equalOrNull(right.pelot) &&

    !equalOrNull(left.toeBarMaterial) &&
    !equalOrNull(right.toeBarMaterial) &&
    !equalOrNull(left.toeBar) &&
    !equalOrNull(right.toeBar)
}

/**
 * Check if a fields is an empty string, null or undefined.
 *
 * @param {string} value - value to check
 * @returns boolean with true or false
 */
function equalOrNull (value) {
    return value === '' || value === null || value === undefined
}
