import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './style'
import TherapistDisplay from '../../components/TherapistDisplay'
import NavigationButtons from '../../components/NavigationButtons'
import { withStyles } from '@mui/styles'
import General from '../../tabs/general'
import OsaPattern from '../../tabs/osaPattern'
import OsaLower from '../../tabs/osaLower'
import { setOrderType } from '../../store/reducers/general'
import OsaFinish from '../../tabs/osaFinish'
import { useNavigate } from 'react-router-dom'
import ValidationErrorDialog from '../../components/ValidationErrorDialog'

const OSAPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const other = useSelector((state) => state.other)

    /**
     */
    useEffect(() => {
        dispatch(setOrderType('OSA'))
    }, [])

    useEffect(() => {
        if (other.allValid) {
            navigate('/overview')
        }
    }, [other.allValid, other.errorDialog])

    return (
        <div>
            <TherapistDisplay />
            <NavigationButtons />
            <General />
            <OsaPattern/>
            <OsaLower/>
            <OsaFinish/>
            <ValidationErrorDialog />
            <NavigationButtons />
        </div>
    )
}
export default withStyles(useStyles)(OSAPage)
