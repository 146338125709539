import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState.js'
import { validateAllFields } from './validation.js'
import { validateMinMax } from '../genericFunctions/index.js'
import dataProvider from '../../../lib/dataProvider.js'

const data = dataProvider.insole

const insoleSlice = createSlice({
    name: 'insole',
    initialState,
    reducers: {
        insoleData (state, action) {
            const key = action.payload.key
            const value = action.payload.value === null ? '' : action.payload.value
            const side = action.payload.side || ''
            switch (key) {
            default:
                if (side === '') {
                    state.insoleData[key] = value
                } else {
                    if (side === 'left' && state.insoleData.left[key] === state.insoleData.right[key]) {
                        state.insoleData.left[key] = value
                        state.insoleData.right[key] = value
                    } else {
                        state.insoleData[side][key] = value
                    }
                }
                // check if theres a minMax value
                if (data[key] !== undefined && data[key].min !== undefined) {
                    validateMinMax(data[key], state, action, 'insoleErrors')
                }
                break
            }
        },
        insoleAccordion (state, action) {
            switch (action.payload.key) {
            case 'accordion':
                if (!state.insoleAccordion.hasBeenOpened && state.insoleAccordion.accordion && !action.payload.value) {
                    state.insoleAccordion.hasBeenOpened = true
                }
                state.insoleAccordion.accordion = action.payload.value
                validateAllFields(state, action)
                break
            case 'validate':
                validateAllFields(state, action)
                break
            case 'validateFalse':
                state.insoleAccordion.validate = false
                break
            case 'hasBeenOpened':
                state.insoleAccordion.hasBeenOpened = true
                break
            }
        },
        insoleClose (state) {
            state.insoleAccordion.accordion = false
            state.insoleAccordion.hasBeenOpened = true
            validateAllFields(state)
        },
        importInsole (state, action) {
            state.insoleData = action.payload
        },
        resetState (state) {
            state.insoleData = initialState.insoleData
        }
    }
})

export const { resetState, insoleData, insoleAccordion, importInsole, insoleClose } = insoleSlice.actions
export default insoleSlice.reducer
