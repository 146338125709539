export const initialState = {
    osaPatternData: {
        supplier: '',
        modelHeritage: '',
        modelNumber: '',
        description: '',
        customizePattern: false,
        note: '',
        mainLeather: {
            name: '',
            number: ''
        },
        firstExtraLeather: {
            name: '',
            number: ''
        },
        secondExtraLeather: {
            name: '',
            number: ''
        },
        thirdExtraLeather: {
            name: '',
            number: ''
        },
        collarPaddingType: '',
        collarPadding: '',
        solePadding: '',
        mudguardLeather: '',
        developMethod: '',
        laces: [],
        laceRings: '',
        laceHooks: '',
        velcro: false,
        velcroAmount: '',
        velcroWidth: '',
        zipper: '',
        elastic: '',
        buckle: '',
        changedLasts: false,
        left: {
            collarPaddingThickness: '',
            solePaddingThickness: '',
            upperHeight: '',
            heelHeight: '',
            contrefort: '',
            lining: '',
            entryPoint: '',
            reverseVelcro: false
        },
        right: {
            collarPaddingThickness: '',
            solePaddingThickness: '',
            upperHeight: '',
            heelHeight: '',
            contrefort: '',
            lining: '',
            entryPoint: '',
            reverseVelcro: false
        }
    },
    osaPatternAccordion: {
        accordion: false,
        validate: false,
        hasBeenOpened: false
    },
    osaPatternErrors: {
        message: [],
        number: false,
        collarPadding: false,
        mudguardLeather: false,
        developMethod: false,
        laces: false,
        laceRings: false,
        laceHooks: false,
        velcro: false,
        velcroAmount: false,
        velcroWidth: false,
        zipper: false,
        elastic: false,
        buckle: false,
        left: {
            collarPaddingThickness: false,
            solePaddingThickness: false,
            upperHeight: false,
            heelHeight: false,
            contrefort: false,
            lining: false,
            entryPoint: false,
            reverseVelcro: false
        },
        right: {
            collarPaddingThickness: false,
            solePaddingThickness: false,
            upperHeight: false,
            heelHeight: false,
            contrefort: false,
            lining: false,
            entryPoint: false,
            reverseVelcro: false
        },
        mainLeather: {
            name: '',
            number: ''
        },
        firstExtraLeather: {
            name: '',
            number: ''
        },
        secondExtraLeather: {
            name: '',
            number: ''
        },
        thirdExtraLeather: {
            name: '',
            number: ''
        }
    }
}
