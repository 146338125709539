import React, { useEffect } from 'react'
import useStyles from './style'
import TherapistDisplay from '../../components/TherapistDisplay'
import NavigationButtons from '../../components/NavigationButtons'
import { withStyles } from '@mui/styles'
import General from '../../tabs/general'
import OsbOutsole from '../../tabs/osbOutsole'
import { useDispatch, useSelector } from 'react-redux'
import { patientData, setError, setOrderType } from '../../store/reducers/general'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from '../../lib/api'
import moment from 'moment'
import ValidationErrorDialog from '../../components/ValidationErrorDialog'
import Insole from '../../tabs/insole'
import { setPreviousOrders } from '../../store/reducers/other'

const OvacPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [queryParameters] = useSearchParams()

    const other = useSelector((state) => state.other)
    const osb = useSelector((state) => state.osbShoe.osbShoeData)

    /**
     */
    useEffect(() => {
        dispatch(setOrderType('OVAC'))
    }, [])

    useEffect(() => {
        if (other.allValid) {
            navigate('/overview')
        }
    }, [other.allValid, other.errorDialog])
    /**
     * get the authenticated podiatrist's name and email
     * (activated only in production)
     */
    useEffect(() => {
        // save the patient number when given.
        const patient = queryParameters.get('patient')
        if (patient !== null) dispatch(patientData({ key: 'number', value: patient }))

        const fetchTherapists = () => {
            api.therapists((rsp) => {
                dispatch(patientData({
                    key: 'therapist',
                    value: {
                        name: rsp.name,
                        email: rsp.email
                    }
                }),
                api.setSharepointInformation(rsp.microsoftToken, queryParameters)
                )
            }, (error) => { dispatch(setError(error)) })
        }

        fetchTherapists()
        if (patient === null) return
        api.patients(patient,
            (data) => {
                const bday = moment(data.birthDate).add(2, 'h')
                const lasts = [{ formattedName: '-' }]
                data.lasts.forEach(element => {
                    if (element.removed === null) {
                        lasts.push(element)
                    }
                })
                dispatch(patientData({ key: 'number', value: patient }))
                dispatch(patientData({ key: 'lastName', value: data.lastName }))
                dispatch(patientData({ key: 'birthdate', value: bday }))
                dispatch(patientData({ key: 'gender', value: data.sex.toLowerCase() }))
                dispatch(patientData({ key: 'id', value: `${data.id}` }))
                dispatch(patientData({ key: 'initials', value: `${data.initials}` }))
                dispatch(patientData({ key: 'activeLasts', value: lasts }))
                if (lasts.length === 2) {
                    dispatch(patientData({ key: 'selectedLast', value: lasts[1].formattedName }))
                }

                api.getOrder(data.id, 'ovac', (data) => {
                    const parsedData = []
                    data.forEach(element => {
                        parsedData.push(JSON.parse(element))
                    })
                    const orders = parsedData.sort((a, b) => b.senddate - a.senddate)
                    dispatch(setPreviousOrders(orders))
                }, (error) => {
                    dispatch(setError(error))
                })
            }, (error) => {
                dispatch(setError(error))
            })
    }, [])

    return (
        <div>
            <TherapistDisplay />
            <NavigationButtons />
            <General />
            <OsbOutsole/>
            {osb.insole ? <Insole/> : null}
            <ValidationErrorDialog />
            <NavigationButtons />
        </div>
    )
}
export default withStyles(useStyles)(OvacPage)
