/**
 * validate if fields are not empty.
 *
 * @param {Object} state - current state.
 * @param {Object} value - filled value.
 */
export function validateAllFields (state) {
    if (!state.osaLowerAccordion.hasBeenOpened) return
    state.osaLowerAccordion.validate = true
}
